<template>
  <v-row justify="center">
    <v-dialog
      v-model="cmapDialog"
      width="900px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="green"
            text
            v-bind="attrs"
            v-on="on"
            small
            >
            Request CMAP
            <v-icon>mdi-email</v-icon>
            </v-btn>
      </template>
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Request CMAP to Compliance</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                      <v-row>
                          <v-col>
                              <h4>Remarks</h4>
                          </v-col>
                      </v-row>
                          <v-row>
                <v-col>
                  <v-textarea
                    label="Type Here"
                    auto-grow
                    outlined
                    v-model="remarks"
                  ></v-textarea>
                </v-col>
              </v-row>
              </v-container>
          </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                outlined color="primary"
                text
                @click="cancel"
            >
                Cancel
            </v-btn>
            <v-btn v-if="remarks"
                color="success"
                @click="submit"
            >
                Request
            </v-btn>
            <v-btn v-else disabled>
                Request
            </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ["item"],
    data () {
      return {
        remarks: '',
        cmapDialog: false,
      }
    },
    methods:{
        cancel(){
            this.cmapDialog = false
            this.remarks = ''
        },
        async submit(){

          const payload = {
            id : this.$route.params.id,
            finalPayload: {
              comment: this.remarks
            }
          }

          const res = await this.$store.dispatch('salesProspecting/doRequestCmap', {payload})
          if (res.status === 200){
            this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Email Sent to Compliance!','messagetype':'success'});
            this.cmapDialog = false
            this.remarks = ''
          }
          console.log(payload)
        }
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>