<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="4">
				<v-select
					:items="entityTypes"
					label="*Type of Entity"
					v-model="otherPrimaryDetails.type_of_entity"
					outlined
					dense
					hide-details="auto"
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-select>
			</v-col>
									
			<v-col cols="4">
				<v-text-field
					label="*Government Tax ID"
					hide-details="auto"
					v-model="otherPrimaryDetails.government_tax_id"
					dense
					outlined
					type="number"
					hide-spin-buttons
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-text-field>
			</v-col>
									
			<v-col cols="4">
				<v-text-field
					label="*Company Registration Number"
					hide-details="auto"
					v-model="otherPrimaryDetails.company_registration_number"
					outlined
					dense
					type="number"
					hide-spin-buttons
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-text-field>
			</v-col>
		</v-row>
		
		<v-row class="mb-3">
			<v-col cols="3">
				<v-text-field
					label="*State of Registration"
					hide-details="auto"
					v-model="otherPrimaryDetails.state_of_registration"
					outlined
					dense
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-text-field>
			</v-col>
			
			<v-col cols="3">
				<v-select
					:items="registrationTypes"
					label="*Type of Registration"
					v-model="otherPrimaryDetails.type_of_registration"
					hide-details="auto"
					outlined
					dense
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-select>
			</v-col>
									
			<v-col cols="3">
				<v-menu
				ref="menu"
				v-model="menu"
				:close-on-content-click="false"
				transition="scale-transition"
				offset-y
				min-width="auto"
				>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
					v-model="date"
					label="Year Started"
					prepend-icon="mdi-calendar"
					readonly
					v-bind="attrs"
					v-on="on"
					dense
					outlined
					hide-details="auto"
					></v-text-field>
				</template>
				<v-date-picker
					v-model="date"
					type="month"
					:active-picker.sync="activePicker"
					:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
					min="1950-01-01"
					@change="save"
					:readonly="disabled"
				></v-date-picker>
				</v-menu>

			</v-col>

			<v-col cols="3">
				<v-text-field
					label="*Number of Years In Business"
					v-model="otherPrimaryDetails.years_in_business"
					hide-details="auto"
					outlined
					dense
					type="number"
					hide-spin-buttons
					:rules="genericRule"
					readonly
					>
				</v-text-field>
			</v-col>

			<v-col cols="6">
				<v-text-field
					label="*Mother Location"
					hide-details="auto"
					v-model="otherPrimaryDetails.mother_location"
					outlined
					dense
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-text-field>
			</v-col>
			
			<v-col cols="6">
				<v-autocomplete
					label="*Class Code"
					hide-details="auto"
					v-model="otherPrimaryDetails.class_code_entity"
					outlined
					dense
					:rules="genericRule"
					:readonly="disabled"
					:items="items"
					>
				</v-autocomplete>
			</v-col>


			<v-col :cols="3" v-if="mother_entity_details.mother_entity_id == null">
				<v-checkbox
					v-model="mother_entity_details.is_mother_entity"
					class="mb-5"
					label="Is Mother Entity"
					color="indigo"
					hide-details="auto"
					@change="entityCheckbox"
					:readonly='disabled'
				></v-checkbox>
			</v-col>
			<v-col :cols="9" v-if="mother_entity_details.mother_entity_id == null">
				<v-combobox
					v-if="mother_entity_details.is_mother_entity"
					v-model="mother_entity_details.sub_entities"
					:items="entityList"
					item-text="account_name"
					label="Sub Entities"
					multiple
					small-chips
					outlined
					dense
					hide-details="auto"
					:readonly="disabled"
					>
				</v-combobox>
				<v-combobox
					v-else
					v-model="mother_entity_details.sub_entities"
					:items="entityList"
					item-text="account_name"
					label="Sub Entities"
					multiple
					small-chips
					outlined
					dense
					hide-details="auto"
					readonly
					>
				</v-combobox>
			</v-col>
			
			<v-col cols="6" v-if="mother_entity_details.mother_entity_id != null">
				<v-text-field
					label="Mother Entity"
					:value="checkMotherEntity(mother_entity_details.mother_entity_id)"
					hide-details="auto"
					outlined
					dense
					readonly
					>
				</v-text-field>
			</v-col>

			<v-col cols="6">
				<v-text-field
					label="*Company ID"
					hide-details="auto"
					v-model="otherPrimaryDetails.company_id"
					outlined
					dense
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-text-field>
			</v-col>

			<v-col cols="6">
			</v-col>
			
			<v-col cols="12">
				<v-radio-group
					v-model="radio1value"
					mandatory
					row
					@change="radio1function($event)"
					:readonly="disabled"
				>
					<v-radio
						label="Remittance Service Agent/s (RSA)"
						value="Remittance Service Agent/s (RSA)"
					></v-radio>
					<v-radio
						label="Tie-up"
						value="Tie-up"
					></v-radio>
				</v-radio-group>
			</v-col>

			<v-col cols="12" v-if="documents.isWestern || existingUploadedFiles.is_western_union">
				<v-radio-group
					v-model="radio2value"
					mandatory
					row
					@change="radio2function($event)"
					:readonly="disabled"
				>
					<v-radio
						label="KYA Pending"
						value="KYA Pending"
					></v-radio>
					<v-radio
						label="KYA Recertification"
						value="KYA Recertification"
					></v-radio>
					<v-radio
						label="KYA Certified"
						value="KYA Certified"
					></v-radio>
				</v-radio-group>
			</v-col>				
		</v-row>
								
		<v-row>
			<v-col>
				<h4>Services Offered</h4>
			</v-col>
		</v-row>
								
		<v-row>
			<v-col cols="12">
				<v-checkbox
					label="Western Union"
					v-model="otherPrimaryDetails.is_western_union"
					:readonly="disabled"
					>
				</v-checkbox>
			</v-col>
									
			<v-col cols="10">
				<v-combobox
					v-model="otherPrimaryDetails.list_of_non_ex_remco"
					:items="nonexRemcoItems"
					label="*Non-Ex Remco"
					multiple
					small-chips
					outlined
					dense
					hide-details="auto"
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-combobox>
			</v-col>
			<v-col cols="2"
				v-if="!disabled"
			>
				<v-checkbox
					label="Select All"
					:readonly="disabled"
					false-value="0"
					true-value="1"
					@change="nonexAll"
					v-model="nonexCheckBox"
					>
				</v-checkbox>
			</v-col>

									
			<v-col cols="10">
				<v-combobox
					v-model="otherPrimaryDetails.list_of_avp_products"
					:items="avpItems"
					label="*AVP"
					multiple
					small-chips
					outlined
					hide-details="auto"
					dense
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-combobox>
			</v-col>
			<v-col cols="2"
				v-if="!disabled"
			>
				<v-checkbox
					label="Select All"
					:readonly="disabled"
					false-value="0"
					true-value="1"
					@change="avpAll"
					v-model="avpCheckBox"
					>
				</v-checkbox>
			</v-col>
									
			<v-col cols="10">
				<v-combobox
					v-model="otherPrimaryDetails.business_information"
					:items="businessInformationItems"
					label="*Business Information"
					multiple
					chips
					outlined
					hide-details="auto"
					dense 
					small-chips
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-combobox>
			</v-col>
			<v-col cols="2"
				v-if="!disabled"
			>
				<v-checkbox
					label="Select All"
					:readonly="disabled"
					false-value="0"
					true-value="1"
					v-model="businessInfoCheckBox"
					@change="businessInfoAll"
					>
				</v-checkbox>
			</v-col>
									
			<v-col cols="10">
				<v-combobox
					v-model="otherPrimaryDetails.business_type_of_sub_agents"
					:items="businessTypeSAItems"
					label="*Business Type of Sub Agents"
					multiple
					chips
					outlined
					hide-details="auto"
					dense
					small-chips
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-combobox>
			</v-col>
			<v-col cols="2"
				v-if="!disabled"
			>
				<v-checkbox
					label="Select All"
					:readonly="disabled"
					false-value="0"
					true-value="1"
					v-model="businessTypeCheckBox"
					@change="businessTypeAll"
					>
				</v-checkbox>
			</v-col>
									
			<v-col cols="10">
				<v-combobox
					v-model="otherPrimaryDetails.top_reasons_for_WU"
					:items="reasonsForWU"
					label="*Top Reasons for WU Sales"
					multiple
					chips
					outlined
					hide-details="auto"
					dense
					small-chips
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-combobox>
			</v-col>
			<v-col cols="2"
				v-if="!disabled"
			>
				<v-checkbox
					label="Select All"
					:readonly="disabled"
					false-value="0"
					true-value="1"
					v-model="topReasonCheckBox"
					@change="topReasonAll"
					>
				</v-checkbox>
			</v-col>
			
			<v-col cols="10">
				<v-combobox
					v-model="otherPrimaryDetails.typical_customers"
					:items="typicalCustomers"
					label="*Typical Customers"
					multiple
					chips
					outlined
					hide-details="auto"
					dense
					small-chips
					:rules="genericRule"
					:readonly="disabled"
					>
				</v-combobox>
			</v-col>
			<v-col cols="2"
				v-if="!disabled"
			>
				<v-checkbox
					label="Select All"
					:readonly="disabled"
					false-value="0"
					true-value="1"
					v-model="typicalCheckBox"
					@change="typicalAll"
					>
				</v-checkbox>
			</v-col>
		</v-row>

</div>

</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ['disabled'],
	data () {
		return {
			radiosex: 'Remittance Service Agent/s (RSA)',
			nonexCheckBox: '0',
			avpCheckBox: '0',
			businessInfoCheckBox: '0',
			businessTypeCheckBox: '0',
			topReasonCheckBox: '0',
			typicalCheckBox: '0',
			activePicker: null,
			date: null,
			menu: false,
			bspMenu: false,
			kyaMenu: false,
			items: ["Send and Receive", "Send Only", "Receive Only"],
			row: null,
            entityTypes: [
				{
					text: 'Publicly Traded',
					value: 'Publicly Traded'
				},
					{
					text: 'Privately Held',
					value: 'Privately Held'
				},
				{
					text: 'Government Held',
					value: 'Government Held'
				},
				{
					text: 'Hybrid',
					value: 'Hybrid'
				},
				{
					text: 'Others',
					value: 'Others'
				},
			],
			registrationTypes: [
				{
					text: 'DTI',
					value: 'DTI'
				},
				{
					text: 'CDA',
					value: 'CDA'
				},
				{
					text: 'SEC',
					value: 'SEC'
				},
			],
			businessInformationItems: [
				'ABMT',
				'Convenience Pay',
				'Money In Minutes',
				'Money Transfer',
				'Money Order',
				'Next Day',
				'Paymap',
				'Quick Cash',
				'Quick Collection',
				'Quick Pay',
				'Prepaid Cards',
				'None'
			],
			typicalCustomers: [
				'College Students',
				'Individuals',
				'Seasonal/Migrant Workers',
				'Merchants',
				'None'
			],
			reasonsForWU: [
				'Bill Payment',
				'Emergency Funds',
				'Gift Card/Prepaid Reload',
				'Money Transfer (Family)',
				'Money Transfer (Non Family)',
				'Payroll',
				'Retail Purposes',
				'None'
			],
			businessTypeSAItems: [
				'Bank',
				'Cash in Services',
				'Courier/Parcel',
				'Currency Exchange',
				'Financial Services',
				'Import/Export Industry',
				'Insurance',
				'Money Lender',
				'Money Transmitter',
				'Pawnshop',
				'None'
			],
			avpItems: [
				'Pera Palit - SBB',
				'Pera Palit - MC',
				'Pera Travel - Cebu Pacific',
				'Pera Travel - Air Asia',
				'Pera Pay - Bayad Center',
				'Pera Load - E-Load Load Central',
				'Pera Protect - Malayan Insurance',
				'Pera Protect - Dengue Protect Plus',
				'Pera Protect - CTPL Insurance',
				'Pera Payapa - Pera Payapa',
				'Pera Dali - ATM',
				'Pera CICO - GCash Cash In',
				'Pera CICO - GCash Cash Out',
				'Pera CICO - Coins Cash In',
				'Pera CICO - DragonPay Cash In',
				'Pera CICO - DragonPay Cash Out',
				'Pera CICO - PayMaya Add Money',
				'Pera CICO - PHV Cash In',
				'Pera CICO - Non PeraHub Cash In',
				'Pera CICO - UB Deposit',
				'Lazada - Lazada DOP',
				'Others - Regular Eon Cards',
				'Others - T2',
				'Others - Converge',
				'None'
			],
			nonexRemcoItems: [
				'Ayannah',
				'BPI',
				'Cebuana International',
				'Cebuana',
				'InstantCash',
				'Intel Express',
				'iRemit',
				'JapanRemit',
				'Landbank',
				'Metrobank',
				'Paymaya',
				'Remitly',
				'Ria',
				'Smart Money',
				'Smart Padala',
				'Stellar',
				'Tranglo',
				'Transfast',
				'UniTeller',
				'USSC',
				'Xpress Money',
				'None'
			],
			radio1value: '',
			radio2value: '',
			entityList: []
		};
	},
	watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
		otherPrimaryDetails(value) {
			if (value.list_of_non_ex_remco.length == this.nonexRemcoItems.length - 1){
				this.nonexCheckBox = '1'
			}
			if (value.list_of_avp_products.length == this.avpItems.length - 1){
				this.avpCheckBox = '1'
			}
			if (value.business_information.length == this.businessInformationItems.length - 1){
				this.businessInfoCheckBox = '1'
			}
			if (value.business_type_of_sub_agents.length == this.businessTypeSAItems.length - 1){
				this.businessTypeCheckBox = '1'
			}
			if (value.top_reasons_for_WU.length == this.reasonsForWU.length - 1){
				this.topReasonCheckBox = '1'
			}
			if (value.typical_customers.length == this.typicalCustomers.length - 1){
				this.typicalCheckBox = '1'
			}
		},
		primaryDetailsRadio1: {
			handler (val) {
				this.radio1value = val
			},
			deep: true
		},
		primaryDetailsRadio2: {
			handler (val){
				this.radio2value = val
			},
			deep: true
		}
    },
    methods: {
		checkMotherEntity(e){
			let motherEntity = this.entityList.find(obj => obj.id == e)
			return motherEntity.account_name
		},
		entityCheckbox(e){
			if (e == false){
				this.mother_entity_details.sub_entities = []
			}
		},
		radio1function(value){
			if (value == 'Remittance Service Agent/s (RSA)') {
				this.otherPrimaryDetails.is_rsa = true
				this.otherPrimaryDetails.is_tie_up = false
			} else {
				this.otherPrimaryDetails.is_rsa = false
				this.otherPrimaryDetails.is_tie_up = true
			}
		},
		radio2function(value){
			if (value == 'KYA Pending)') {
				this.otherPrimaryDetails.is_kya_pending = true
				this.otherPrimaryDetails.is_kya_recertification = false
				this.otherPrimaryDetails.is_kya_certified = false
			} else if (value == 'KYA Recertification'){
				this.otherPrimaryDetails.is_kya_pending = false
				this.otherPrimaryDetails.is_kya_recertification = true
				this.otherPrimaryDetails.is_kya_certified = false
			} else if (value == 'KYA Certified'){
				this.otherPrimaryDetails.is_kya_pending = false
				this.otherPrimaryDetails.is_kya_recertification = false
				this.otherPrimaryDetails.is_kya_certified = true
			}
		},
		change_rsa(v){
			if (v == true){
				this.otherPrimaryDetails.is_rsa == true
			}
		},
		change_tie_up(v){
			if (v == true){
				this.otherPrimaryDetails.is_tie_up == true
			}
		},
		nonexAll(value){
			if (value == (1 || true)){
				const arr = this.nonexRemcoItems.slice(0,-1)
				this.otherPrimaryDetails.list_of_non_ex_remco = arr
			} else {
				this.otherPrimaryDetails.list_of_non_ex_remco = []
			}
		},
		avpAll(value){
			if (value == (1 || true)){
				const arr = this.avpItems.slice(0,-1)
				this.otherPrimaryDetails.list_of_avp_products = arr
			} else {
				this.otherPrimaryDetails.list_of_avp_products = []
			}
		},
		businessInfoAll(value){
			if (value == (1 || true)){
				const arr = this.businessInformationItems.slice(0,-1)
				this.otherPrimaryDetails.business_information = arr
			} else {
				this.otherPrimaryDetails.business_information = []
			}
		},
		businessTypeAll(value){
			if (value == (1 || true)){
				const arr = this.businessTypeSAItems.slice(0,-1)
				this.otherPrimaryDetails.business_type_of_sub_agents = arr
			} else {
				this.otherPrimaryDetails.business_type_of_sub_agents = []
			}
		},
		topReasonAll(value){
			if (value == (1 || true)){
				const arr = this.reasonsForWU.slice(0,-1)
				this.otherPrimaryDetails.top_reasons_for_WU = arr
			} else {
				this.otherPrimaryDetails.top_reasons_for_WU = []
			}
		},
		typicalAll(value){
			if (value == (1 || true)){
				const arr = this.typicalCustomers.slice(0,-1)
				this.otherPrimaryDetails.typical_customers = arr
			} else {
				this.otherPrimaryDetails.typical_customers = []
			}
		},
		save (date) {
			this.$refs.menu.save(date)
			var strFirstFour = this.date.substring(0,4);
			let year = parseInt(strFirstFour,10)
			var today = new Date();
			var yearToday = today.getFullYear()
			let result = (yearToday - year)

			this.otherPrimaryDetails.years_in_business = result.toString()
		},
	},
    computed: {
		...mapGetters({
            countries: 'salesProspecting/countries',
            cities: 'salesProspecting/cities',
			otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
			genericRule: 'salesProspecting/genericRule',
			primaryDetailsRadio1: 'salesProspecting/primaryDetailsRadio1',
			primaryDetailsRadio2: 'salesProspecting/primaryDetailsRadio2',
			documents: 'salesProspecting/documents',
			existingUploadedFiles: 'salesProspecting/existingUploadedFiles',
			mother_entity_details: 'salesProspecting/mother_entity_details'
		}),
	},
	async created () {
		if (this.otherPrimaryDetails.list_of_non_ex_remco == this.nonexRemcoItems){
			this.nonexCheckBox = '1'
		} else {
			this.nonexCheckBox = '0'
		}

		const res = await this.$store.dispatch('salesProspecting/doGetAllListSalesProspecting')
		res.data.result.map( e => {
			let newObj = {
				id: '',
				account_name: ''
			}
			newObj.id = e.id
			newObj.account_name = e.sp_primary_details.account_name
			if (newObj.id !== parseInt(this.$route.params.id)) {
				this.entityList.push(newObj);
			}	
		})
	}
};
</script>
