<template>
   <div>
        <v-card class="mx-6 py-0">
            <v-row class="px-2 py-2 px-md-1" no-gutters align="center">
                <v-col class="d-flex justify-md-start px-4" :cols="12" :md="3">
                    <v-avatar class="mr-2" size="30px" :style="avatarColor('InnoTech Inc.')">
                    <span class="white--text text-h5">{{}}</span>
                    </v-avatar>
                    <h3>{{ primaryDetails.account_name }}</h3>
                </v-col>
                <v-col :cols="12" :md="2" class="d-flex justify-md-center px-4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>Date Created</small></v-list-item-title>
                            <v-list-item-subtitle><small><b v-if="primaryDetails.created_at">{{ formatDate(primaryDetails.created_at) }}</b></small></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col :cols="12" :md="2" class="d-flex justify-md-center px-4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>Status</small></v-list-item-title>
                            <v-list-item-subtitle><small><b>{{ primaryDetails.status }}</b></small></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col :cols="12" :md="2" class="d-flex justify-md-center px-4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>Profile</small></v-list-item-title>
                            <v-list-item-subtitle><small><b>{{ primaryDetails.profile }}</b></small></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col :cols="12" :md="3" class="d-flex justify-md-end px-4">
                    <v-btn color="primary" outlined @click="$router.push({ path: '/sales-prospecting/view-profile/' + $route.params.id})">
                        View Profile
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
   </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns'
export default {
    computed: {
		...mapGetters({
            primaryDetails: 'salesProspecting/primaryDetails'
		}),
	},
    methods: {
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
        avatarColor (fullname, ligthness = 40, saturation = 75) {
            let hash = 0

            if (fullname) {
                for (let i = 0; i < fullname.length; i++) {
                hash = fullname.charCodeAt(i) + ((hash << 5) - hash)
                }
                const hue = hash % 360
                return `background-color: hsl(${hue}, ${saturation}%, ${ligthness}%); color:white; !important`
            }
            return ''
        },
        avatarInitial (fullname)  {
            let initial = ''

            if (fullname) {
                let name = ''

                fullname = fullname.toUpperCase()

                if (fullname.indexOf(',') !== -1) {
                fullname = fullname.split(',')
                name = fullname[1]?.replace(/(^\s+|\s+$)/g, '')
                } else {
                name = fullname
                }

                initial = name.substr(0, 1)
            }
            return initial
        }
    }
};
</script>
