<template>
	<div>
		<div>
			<v-row>
				<v-col>
					<h4>Primary Address</h4>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 1"
						hide-details="auto"
						outlined
						v-model="primaryBusinessAddress.address_line_1"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 2"
						hide-details="auto"
						outlined
						v-model="primaryBusinessAddress.address_line_2"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						label="*Country"
						hide-details="auto"
						outlined
						item-text="country"
						item-value="country"
						v-model="primaryBusinessAddress.country"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						v-if="disabled == true"
						label="*Province"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.province"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Province"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.province"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						@change="getRegion"
						:items="province"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || businessAddCity.length == 0"
						label="*City"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.city"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*City"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.city"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						:items="businessAddCity"
						@change="getZip"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						label="*Region"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.region"
						dense
						readonly
						:rules="genericRule"
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || businessAddZip.length == 0"
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.postal_code"
						dense
						type="number"
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.postal_code"
						dense
						type="number"
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						:items="businessAddZip"
						>
					</v-autocomplete>
				</v-col>
			</v-row>
		</div>
		
		<div>
			<v-row>
				<v-col>
					<h4>Mailing Address</h4>
				</v-col>
			</v-row>
								
			<v-checkbox
				label="Same As Primary Address"
				v-model="primaryBusinessAddress.is_same_primary"
				@click="sameAddress"
				:disabled="checkAddress() || disabled"
				>
			</v-checkbox>

		<div v-if="!primaryBusinessAddress.is_same_primary">

			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 1"
						hide-details="auto"
						outlined
						v-model="primaryBusinessAddress.mailing_address_line_1"
						dense
						:rules="genericRule"
						:readonly="disabled"
						
						>
					</v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 2"
						hide-details="auto"
						outlined
						v-model="primaryBusinessAddress.mailing_address_line_2"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						label="*Country"
						hide-details="auto"
						outlined
						item-text="country"
						item-value="country"
						v-model="primaryBusinessAddress.mailing_country"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						v-if="disabled == true"
						label="*Province"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_province"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Province"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_province"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						@change="getMailingRegion"
						:items="province"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || mailingBusinessAddCity.length == 0"
						label="*City"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_city"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*City"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_city"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:items="mailingBusinessAddCity"
						:readonly="disabled"
						@change="getMailingZip"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						label="*Region"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_region"
						dense
						readonly
						:rules="genericRule"
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || mailingBusinessAddZip.length == 0"
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_postal_code"
						dense
						type="number"
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="primaryBusinessAddress.mailing_postal_code"
						dense
						type="number"
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						:items="mailingBusinessAddZip"
						>
					</v-autocomplete>
				</v-col>
			</v-row>
			</div>
		</div>
		
		<div>
			<v-row>
				<v-col>
					<h4>Contact Details</h4>
				</v-col>
			</v-row>
								
			<v-row>
				<v-col
					cols="12"
					sm="12"
					md="6"
					>
					<v-text-field
						label="Landline Number"
						outlined
						v-model="primaryBusinessAddress.landline"
						hide-details="auto"
						dense
						type="number"
						hide-spin-buttons
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
				
				<v-col
					cols="12"
					sm="12"
					md="6"
					>
					<v-text-field
						label="*Cellphone Number"
						outlined
						v-model="primaryBusinessAddress.cellphone"
						hide-details="auto"
						dense
						type="number"
						hide-spin-buttons
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
								
			<v-row>
				<v-col
					cols="12"
					sm="12"
					md="6"
					>
					<v-text-field
						label="*Designated Receiver"
						outlined
						v-model="primaryBusinessAddress.mailing_designated_receiver"
						hide-details="auto"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
									
				<v-col
					cols="12"
					sm="12"
					md="6"
					>
					<v-text-field
						label="*Receiver Contact Number"
						outlined
						v-model="primaryBusinessAddress.mailing_contact_number"
						hide-details="auto"
						dense
						type="number"
						hide-spin-buttons
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ['disabled'],
	data () {
		return {
			items: ['1600', '1200', '1550'],
			sameAdd: false
		};
	},
	mounted () {
		if (this.primaryBusinessAddress.address_line_1 === this.primaryBusinessAddress.mailing_address_line_1 && this.primaryBusinessAddress.address_line_2 === this.primaryBusinessAddress.mailing_address_line_2 && this.primaryBusinessAddress.city === this.primaryBusinessAddress.mailing_city && this.primaryBusinessAddress.country === this.primaryBusinessAddress.country && this.primaryBusinessAddress.region === this.primaryBusinessAddress.mailing_region && this.primaryBusinessAddress.postal_code === this.primaryBusinessAddress.mailing_postal_code) {
			this.sameAdd = true
		} else {
			this.sameAdd = false
		}
	},
    computed: {
		...mapGetters({
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
			genericRule: 'salesProspecting/genericRule',
			zip: 'salesProspecting/zip',
			province: 'salesProspecting/province',
			businessAddCity: 'salesProspecting/businessAddCity',
			businessAddZip: 'salesProspecting/businessAddZip',
			mailingBusinessAddCity: 'salesProspecting/mailingBusinessAddCity',
			mailingBusinessAddZip: 'salesProspecting/mailingBusinessAddZip',
			hrisToken: 'auth/hrisToken',
		}),
	},
	methods: {
		checkAddress () {
			if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '') {
				return false
			} else {
				return true
			}
		},
		sameAddress () {
			if (this.primaryBusinessAddress.is_same_primary){
				console.log('check')
				console.log(this.primaryBusinessAddress.is_same_primary)
				this.primaryBusinessAddress.is_same_primary = true
				this.primaryBusinessAddress.mailing_address_line_1 = this.primaryBusinessAddress.address_line_1
				this.primaryBusinessAddress.mailing_address_line_2 = this.primaryBusinessAddress.address_line_2
				this.primaryBusinessAddress.mailing_country = this.primaryBusinessAddress.country
				this.primaryBusinessAddress.mailing_city = this.primaryBusinessAddress.city
				this.primaryBusinessAddress.mailing_region = this.primaryBusinessAddress.region
				this.primaryBusinessAddress.mailing_postal_code = this.primaryBusinessAddress.postal_code
				this.primaryBusinessAddress.mailing_province = this.primaryBusinessAddress.province
			} else {
				console.log('uncheck')
				console.log(this.primaryBusinessAddress.is_same_primary)
				this.primaryBusinessAddress.mailing_address_line_1 = ''
				this.primaryBusinessAddress.mailing_address_line_2 = ''
				this.primaryBusinessAddress.mailing_country = 'Philippines'
				this.primaryBusinessAddress.mailing_city = ''
				this.primaryBusinessAddress.mailing_province = ''
				this.primaryBusinessAddress.mailing_region = ''
				this.primaryBusinessAddress.mailing_postal_code = ''
			}
		},
		async getRegion(province){
			this.primaryBusinessAddress.postal_code = ''
			this.primaryBusinessAddress.city = ''
			const payload = {
				payload : province,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetBusinessAddRegion', {payload})
			const finalPayload = {
				payload : this.primaryBusinessAddress.region,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetBusinessAddCity', {finalPayload})
		},
		async getZip(city){
			const payload = {
				payload : this.primaryBusinessAddress.region,
				city : city,
				token: this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetBusinessAddZip', {payload})
		},

		async getMailingRegion(province){
			this.primaryBusinessAddress.mailing_postal_code = ''
			const payload = {
				payload : province,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetMailingBusinessAddRegion', {payload})
			const finalPayload = {
				payload : this.primaryBusinessAddress.mailing_region,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetMailingBusinessAddCity', {finalPayload})
		},

		async getMailingZip(city){
			const payload = {
				payload : this.primaryBusinessAddress.mailing_region,
				city : city,
				token: this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetMailingBusinessAddZip', {payload})
		},


		// async getAddress(zip){
		// 	const payload = {
		// 		payload : zip,
		// 		token : this.hrisToken
		// 	}
		// 	await this.$store.dispatch('salesProspecting/doGetAddressBusinessAdd', {payload})
		// },
		// async getMailingAddress(zip){
		// 	const payload = {
		// 		payload : zip,
		// 		token : this.hrisToken
		// 	}
		// 	await this.$store.dispatch('salesProspecting/doGetMailingAddressBusinessAdd', {payload})
		// }
	}
};
</script>
