<template>
    <div class="mt-5">
                        <v-row class="mb-3">
                            <v-col cols="3">
                                <v-text-field
                                    label="*First Name"
                                    hide-details="auto"
                                    v-model="owners.first_name"
                                    outlined
                                    dense
                                    :disabled="disabled"
                                    :rules="genericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Middle Name"
                                    hide-details="auto"
                                    v-model="owners.middle_name"
                                    outlined
                                    dense
                                    :disabled="disabled"
                                    :rules="genericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Last Name"
                                    hide-details="auto"
                                    v-model="owners.last_name"
                                    outlined
                                    dense
                                    :disabled="disabled"
                                    :rules="genericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Suffix"
                                    hide-details="auto"
                                    v-model="owners.suffix"
                                    outlined
                                    dense
                                    :disabled="disabled"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Percentage of Ownership"
                                    hide-details="auto"
                                    v-model="owners.percentage_ownership"
                                    outlined
                                    dense
                                    type="number"
                                    hide-spin-buttons
                                    :disabled="disabled"
                                    :rules="genericRule"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" v-if="otherPrimaryDetails.type == 'Cooperative/Hybrid'">
                                <v-text-field
                                    label="*No of Members"
                                    hide-details="auto"
                                    v-model="owners.no_of_members"
                                    outlined
                                    type="number"
                                    hide-spin-buttons
                                    dense
                                    :disabled="disabled"
                                    :rules="genericRule"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
							<v-col>
								<h4>Primary Contact Address</h4>
							</v-col>
						</v-row>

                        <v-checkbox
                            label="Same As Primary Address"
                            v-model="owners.is_same_primary"
                            @click="sameAddress"
                            :disabled="checkAddress() || disabled"
                            >
                        </v-checkbox>

						<v-row>
							<v-col>
								<v-text-field
									label="*Address Line 1"
									hide-details="auto"
                                    v-model="owners.address_line_1"
									outlined
                                    dense
                                    :disabled="disabled"
                                    :rules="genericRule"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-text-field
									label="*Address Line 2"
									hide-details="auto"
                                    v-model="owners.address_line_2"
									outlined
                                    dense
                                    :disabled="disabled"
                                    :rules="genericRule"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col
								cols="12"
								sm="6"
								md="2"
								>
								<v-text-field
									label="*Country"
                                    readonly
                                    item-text="country"
                                    item-value="country"
                                    v-model="owners.country"
									outlined
                                    dense
                                    hide-details="auto"
                                    :disabled="disabled"
                                    :rules="genericRule"
								>
								</v-text-field>
							</v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                >
                                <v-text-field
                                    v-if="disabled == true"
                                    label="*Province"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.province"
                                    dense
                                    :rules="genericRule"
                                    :readonly="disabled"
                                    >
                                </v-text-field>
                                <v-autocomplete
                                    v-else
                                    label="*Province"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.province"
                                    dense
                                    :rules="genericRule"
                                    hide-spin-buttons
                                    :readonly="disabled"
                                    @change="getRegion"
                                    :items="province"
                                    >
                                </v-autocomplete>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="2"
                                >
                                <v-text-field
                                    v-if="disabled == true || ownersCity.length == 0"
                                    label="*City"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.city"
                                    dense
                                    :rules="genericRule"
                                    readonly
                                    >
                                </v-text-field>
                                <v-autocomplete
                                    v-else
                                    label="*City"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.city"
                                    dense
                                    :rules="genericRule"
                                    hide-spin-buttons
                                    :readonly="disabled"
                                    :items="ownersCity"
                                    @change="getZip"
                                    >
                                </v-autocomplete>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                >
                                <v-text-field
                                    label="*Region"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.region"
                                    dense
                                    readonly
                                    :rules="genericRule"
                                    >
                                </v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="2"
                                >
                                <v-text-field
                                    v-if="disabled == true || ownersZip.length == 0"
                                    label="*Postal Code"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.postal_code"
                                    dense
                                    type="number"
                                    :rules="genericRule"
                                    readonly
                                    >
                                </v-text-field>
                                <v-autocomplete
                                    v-else
                                    label="*Postal Code"
                                    outlined
                                    hide-details="auto"
                                    v-model="owners.postal_code"
                                    dense
                                    type="number"
                                    :rules="genericRule"
                                    hide-spin-buttons
                                    :items="ownersZip"
                                    :readonly="disabled"
                                    >
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
							<v-col cols="4">
								<v-text-field
									label="Landline Number"
                                    v-model="owners.landline"
									outlined
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    hide-spin-buttons
                                    :disabled="disabled"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									label="*Cellphone Number"
                                    v-model="owners.cellphone"
									outlined
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    hide-spin-buttons
                                    :disabled="disabled"
                                    :rules="genericRule"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									label="*Email"
                                    v-model="owners.email_address"
									outlined
                                    dense
                                    hide-details="auto"
                                    :disabled="disabled"
                                    :rules="emailRule"
								></v-text-field>
							</v-col>
						</v-row>

                        <v-row>
                            <v-col>
                                <b>Documents</b>
                            </v-col>
                        </v-row>

                       <!-- AMLA -->
                        <v-row>
                            <v-col :cols="3">
                                <v-file-input
                                    v-model="owners.amla.file"
                                    label="*AMLA"
                                    prepend-icon="mdi-paperclip"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :rules="fileRule"
                                    >
                                 </v-file-input>
                            </v-col>
                            <v-col :cols="3">
                                <v-menu
                                    v-model="owners.amla.menuIssued"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    hide-details="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="owners.amla.date_issued"
                                                label="*Date Issued"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!owners.amla.file  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :rules="genericRule"
                                            >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="owners.amla.date_issued"
                                        @input="owners.amla.menuIssued = false"
                                        hide-details="auto"
                                        >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col :cols="3">
                                <v-menu
                                    v-model="owners.amla.menuExpiry"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    hide-details="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="owners.amla.date_expiry"
                                            label="*Expiry Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="!owners.amla.date_issued  ? '' : on"
                                            dense
                                            outlined
                                            hide-details="auto"
                                            :rules="genericRule"
                                            >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="owners.amla.date_expiry"
                                        @input="owners.amla.menuExpiry = false"
                                        hide-details="auto"
                                        >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <!-- Valid ID -->
                        <v-row>
                            <v-col :cols="9">
                                <v-file-input
                                    v-model="owners.valid_id.file"
                                    label="*Valid ID"
                                    prepend-icon="mdi-paperclip"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :rules="fileRule"
                                    >
                                 </v-file-input>
                            </v-col>
                        </v-row>

                        <!-- NBI -->
                        <v-row>
                            <v-col :cols="9">
                                <v-file-input
                                    v-model="owners.nbi.file"
                                    label="*NBI"
                                    prepend-icon="mdi-paperclip"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :rules="fileRule"
                                    >
                                 </v-file-input>
                            </v-col>
                        </v-row>


                        <!-- 2x2 -->
                        <v-row>
                            <v-col :cols="9">
                                <v-file-input
                                    v-model="owners.picture_2x2.file"
                                    label="*2x2 Picture"
                                    prepend-icon="mdi-paperclip"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :rules="fileRule"
                                    >
                                 </v-file-input>
                            </v-col>
                        </v-row>

                        <!-- Court Clearance -->
                        <v-row>
                            <v-col :cols="9">
                                <v-file-input
                                    v-model="owners.court_clearance.file"
                                    label="Court Clearance"
                                    prepend-icon="mdi-paperclip"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    >
                                 </v-file-input>
                            </v-col>
                        </v-row>
                        
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ['disabled'],
    computed: {
		...mapGetters({
            owners: 'salesProspecting/owners',
            primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
            otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
            genericRule: 'salesProspecting/genericRule',
            fileRule: 'salesProspecting/fileRule',
            emailRule: 'salesProspecting/emailRule',
            province: 'salesProspecting/province',
            ownersCity: 'salesProspecting/ownersCity',
            ownersZip: 'salesProspecting/ownersZip',
            hrisToken: 'auth/hrisToken',
		}),
	},
    methods: {
        checkAddress () {
			if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '' && this.primaryBusinessAddress.cellphone !== '') {
				return false
			} else {
				return true
			}
		},
		sameAddress () {
			if (this.owners.is_same_primary){
				console.log('check')
				this.owners.is_same_primary = true
				this.owners.address_line_1 = this.primaryBusinessAddress.address_line_1
				this.owners.address_line_2 = this.primaryBusinessAddress.address_line_2
				this.owners.country = this.primaryBusinessAddress.country
				this.owners.city = this.primaryBusinessAddress.city
				this.owners.region = this.primaryBusinessAddress.region
				this.owners.postal_code = this.primaryBusinessAddress.postal_code
				this.owners.province = this.primaryBusinessAddress.province
				this.owners.landline = this.primaryBusinessAddress.landline
				this.owners.cellphone = this.primaryBusinessAddress.cellphone
			} else {
				console.log('uncheck')
				console.log(this.owners.is_same_primary)
				this.owners.address_line_1 = ''
				this.owners.address_line_2 = ''
				this.owners.country = 'Philippines'
				this.owners.city = ''
				this.owners.province = ''
				this.owners.region = ''
				this.owners.postal_code = ''
				this.owners.landline = ''
				this.owners.cellphone = ''
			}
		},
        async getRegion(province){
			this.owners.postal_code = ''
			this.owners.city = ''
			const payload = {
				payload : province,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetOwnersRegion', {payload})

			const finalPayload = {
				payload : this.owners.region,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetOwnersCity', {finalPayload})
		},
		async getZip(city){
            console.log(city)
			const payload = {
				payload : this.owners.region,
				city : city,
				token: this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetOwnersZip', {payload})
		},
    }
};
</script>
