<template>
	<div>
		<v-row>
			<v-col cols="12">
				<profile-card  />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-stepper
					class="elevation-0"
					v-model="e6"
					vertical
					>

					<v-stepper-step
						:complete="e6 > 1"
						step="1"
						@click="e6 = 1"
						:rules="step1"
						>
						<span class="stepper-step-proceed">Uploading of Documents</span>
					</v-stepper-step>

					<v-stepper-content step="1">
						<v-card
							class="mb-12"
							:elevation="0"
							>
							<v-radio-group
								v-model="businessType"
								row
								>
								<v-radio
									label="Single Proprietor"
									value="Single Proprietor"
								></v-radio>
								<v-radio
									label="Cooperative/Hybrid"
									value="Cooperative/Hybrid"
								></v-radio>
								<v-radio
									label="Corporation"
									value="Corporation"
								></v-radio>
							</v-radio-group>

							<v-form
							ref="soleProprietorForm"
							v-model="soleProprietor"
							>
								<sole-proprietor v-if="businessType === 'Single Proprietor'" />
							</v-form>
							<v-form
							ref="cooperativeForm"
							v-model="cooperativeHybrid"
							>
								<cooperative-hybrid v-if="businessType=== 'Cooperative/Hybrid'" />
							</v-form>
							<v-form
							ref="corporationForm"
							v-model="corporationModel"
							>
								<corporation v-if="businessType=== 'Corporation'"/>
							</v-form>
							
						</v-card>
						<v-divider></v-divider>
						<v-row class="mt-2">
							<v-col>
								<v-btn
									color="primary"
									@click="$router.push({path: '/sales-prospecting'})"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 2"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>

	
								<v-btn
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="uploadDocuments"
									>
									<b>Upload Documents</b>
								</v-btn>


							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="2" 
						:complete="e6 > 2" 
						@click="e6 = 2"
						:rules="step2"
						>
						<span class="stepper-step-proceed">Primary Business Address</span>
					</v-stepper-step>
					<v-stepper-content step="2">
						<v-card
							:elevation="0"
							>
							<v-form ref="step2" v-model="step2form">
								<primary-business-address />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 3"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
								<v-btn
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="save(true)"
									>
									<b>Save As Draft</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step 
						step="3" 
						:complete="e6 > 3" 
						@click="e6 = 3"
						:rules="step3"
						>
						<span class="stepper-step-proceed">Primary Contact Information</span>
					</v-stepper-step>
					<v-stepper-content step="3">
						<v-card
							:elevation="0"
							>
							<v-form ref="step3" v-model="step3form">
								<primary-contact-information />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 4"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
								<v-btn
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="save(true)"
									>
									<b>Save As Draft</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step 
						step="4" 
						:complete="e6 > 4" 
						@click="e6 = 4"
						:rules="step4"
						>
						<span class="stepper-step-proceed">Compliance Officer/Delegate</span>
					</v-stepper-step>
					<v-stepper-content step="4">
						<v-card
							:elevation="0"
							>
							<v-form ref="step4" v-model="step4form">
								<compliance-officer />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 5"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
								<v-btn
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="save(true)"
									>
									<b>Save As Draft</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step 
						step="5" 
						:complete="e6 > 5" 
						@click="e6 = 5"
						:rules="step5"
						>
						<span class="stepper-step-proceed">Business Information</span>
					</v-stepper-step>
					<v-stepper-content step="5">
						<v-card
							:elevation="0"
							>
							<v-form ref="step5" v-model="step6form">
								<business-information />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 6"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
								<v-btn
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="save(true)"
									>
									<b>Save As Draft</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step 
						step="6" 
						:complete="e6 > 6" 
						@click="e6 = 6"
						:rules="step6"
						>
						<span class="stepper-step-proceed">Branch(s)</span>
					</v-stepper-step>
					<v-stepper-content step="6">
						<v-card
							class="mb-12"
							:elevation="0"
							>

							<v-checkbox
								v-if="existingUploadedBranches.length > 1"
								label="Single Location"
								v-model="otherPrimaryDetails.single_location"
								color="indigo"
								hide-details="auto"
								disabled
								class="mb-5"
							></v-checkbox>
							<v-checkbox
								v-else
								label="Single Location"
								color="indigo"
								v-model="otherPrimaryDetails.single_location"
								hide-details="auto"
								class="mb-5"
							></v-checkbox>

							<view-uploaded-branches  @newValue='countBranchPage'/>

							<div class="text-center mb-5" v-if="existingUploadedBranches.length >= 1">
								<v-pagination
								@input="changePage"
								v-model="page"
								:total-visible="5"
								:length="branchPage.length"
								></v-pagination>
							</div>

							<v-divider></v-divider>
							<v-form v-if="otherPrimaryDetails.single_location && existingUploadedBranches.length == 1">

							</v-form>
							<v-form ref="branchToUpload" v-model="branchToUpload"
							v-else>
								<branches :disabled="false" />
							</v-form>
						</v-card>
						<v-divider></v-divider>
						<v-row class="mt-2">
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 7"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>

								<v-btn
									v-if="otherPrimaryDetails.single_location && existingUploadedBranches.length == 1"
									class="mr-4"
									color="primary"
									style="float:right;"
									disabled
									@click="uploadBranches"
									>
									<b>Add Branch</b>
								</v-btn>
								<v-btn
									v-else
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="uploadBranches"
									>
									<b>Add Branch</b>
								</v-btn>

								<v-btn
									v-if="otherPrimaryDetails.single_location && existingUploadedBranches.length == 1"
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									disabled
									@click="draftBranch"
									>
									<b>Save as Draft Branch</b>
								</v-btn>
								<v-btn
									v-else-if="branches.branch_name == ''"
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									disabled
									@click="draftBranch"
									>
									<b>Save as Draft Branch</b>
								</v-btn>
								<v-btn
									v-else
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="draftBranch"
									>
									<b>Save as Draft Branch</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="7" 
						:complete="e6 > 7" 
						@click="e6 = 7"
						:rules="step7"
						>
						<span class="stepper-step-proceed">Ownership</span>
					</v-stepper-step>
					<v-stepper-content step="7">
						<v-card
							class="mb-12"
							:elevation="0"
							>
							<view-uploaded-owners :disabled="false" />
							<v-divider></v-divider>
							<v-form ref="ownerToUpload" v-model="ownerToUpload">
								<owners :disabled="false" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>

								<v-btn
									@click="e6 = 9"
									color="info"
									style="float:right;"
									v-if="hasBod == false"
									>
									<b>Continue</b>
								</v-btn>
								<v-btn
									v-else
									@click="e6 = 8"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>

								<v-btn
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="uploadOwners"
									>
									<b>Add Owner</b>
								</v-btn>

								<v-btn
									v-if="owners.first_name && owners.middle_name && owners.last_name"
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="draftOwners"
									>
									<b>Save as Draft Owner</b>
								</v-btn>
								<v-btn
									v-else
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									disabled
									>
									<b>Save as Draft Owner</b>
								</v-btn>

							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="8" 
						:complete="e6 > 8" 
						@click="e6 = 8"
						v-if="hasBod == true"
						>
						<span class="stepper-step-proceed">Board of Directors</span>
					</v-stepper-step>
					<v-stepper-content step="8" v-if="hasBod == true">

						<v-card
							class="mb-12"
							:elevation="0"
							>
							<ViewUploadedBod :disabled="false" />
							<v-divider></v-divider>
							<v-form ref="bodToUpload" v-model="bodToUpload">
								<BOD :disabled="false" />
							</v-form>
						</v-card>

						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 9"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
								<v-btn
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="uploadBOD"
									>
									<b>Add BOD</b>
								</v-btn>

								<v-btn
									v-if="bod.first_name && bod.middle_name && bod.last_name"
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="draftBod"
									>
									<b>Save as Draft BOD</b>
								</v-btn>
								<v-btn
									v-else
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									disabled
									>
									<b>Save as Draft BOD</b>
								</v-btn>

							</v-col>
						</v-row>
					</v-stepper-content>



					<v-stepper-step step="9" 
						:complete="e6 > 9" 
						@click="e6 = 9"
						:rules="step9"
						>
						<span class="stepper-step-proceed">BSP License</span>
					</v-stepper-step>
					<v-stepper-content step="9">
						<v-card
							class="mb-12"
							:elevation="0"
							>
							<v-form ref="bspToUpload" v-model="bspToUpload">
								<BSPLicense />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="save(false)"
									color="info"
									style="float:right;"
									>
									<b>Submit</b>
								</v-btn>
								<v-btn
									class="mr-4"
									outlined
									color="primary"
									style="float:right;"
									@click="uploadBSP"
									>
									<b>Upload</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>



				</v-stepper>
			</v-col>
		</v-row>		
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileCard from '../components/modules/salesProspecting/ProfileCard.vue';
import SoleProprietor from '../components/SoleProprietor.vue';
import CooperativeHybrid from '../components/modules/salesProspecting/CooperativeHybrid.vue';
import BOD from '../components/modules/salesProspecting/BOD.vue';
import ViewUploadedBod from '../components/modules/salesProspecting/ViewUploadedBod.vue'
import Corporation from '../components/modules/salesProspecting/Corporation.vue';
import Branches from '../components/modules/salesProspecting/Branches.vue';
import Owners from '../components/modules/salesProspecting/Owners.vue';
import PrimaryBusinessAddress from '../components/modules/salesProspecting/PrimaryBusinessAddress.vue';
import PrimaryContactInformation from '../components/modules/salesProspecting/PrimaryContactInformation.vue';
import ComplianceOfficer from '../components/modules/salesProspecting/ComplianceOfficer.vue';
import BusinessInformation from '../components/modules/salesProspecting/BusinessInformation.vue';
import ViewUploadedOwners from '../components/modules/salesProspecting/ViewUploadedOwners.vue';
import ViewUploadedBranches from '../components/modules/salesProspecting/ViewUploadedBranches.vue'
import BSPLicense from '../components/modules/salesProspecting/BSPLicense.vue'
import jsonToFormData from 'json-form-data';
import _ from 'lodash';

export default {
	components: {
		SoleProprietor: SoleProprietor,
		CooperativeHybrid: CooperativeHybrid,
		Branches: Branches,
		Owners: Owners,
		Corporation: Corporation,
		ProfileCard: ProfileCard,
		PrimaryBusinessAddress: PrimaryBusinessAddress,
		PrimaryContactInformation: PrimaryContactInformation,
		ComplianceOfficer: ComplianceOfficer,
		BusinessInformation: BusinessInformation,
		ViewUploadedOwners: ViewUploadedOwners,
		ViewUploadedBranches: ViewUploadedBranches,
		BSPLicense: BSPLicense,
		BOD: BOD,
		ViewUploadedBod: ViewUploadedBod
	},
	async created () {
		const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
		this.is_existing = resSP.data.result[0].is_existing

		await this.$store.dispatch('salesProspecting/doGetProvince', this.hrisToken)

		const payload = {
			mother_entity_id: resSP.data.result[0].mother_entity_id,
			is_mother_entity: resSP.data.result[0].is_mother_entity,
			sub_entities: resSP.data.result[0].sub_entities
		}

		this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS', (payload))


		if (resSP.data.result[0].is_existing == 0){
			this.link = '/sales-prospecting'
		} else if (resSP.data.result[0].is_existing == 1){
			this.link = '/existing-sales-prospecting'
		}
		
		const res = await this.$store.dispatch('salesProspecting/doGetlAllUsers')
		res.data.result.map(item => {
                this.users.push(item)
            })
		this.$store.commit('salesProspecting/SET_SAM_USERS', this.users)

		this.branch_rate =  parseInt(resSP.data.result[0].completion_rate.sp_branch_rate)

		this.countBranchPage(resSP.data.result[0].sp_branch)
	
		this.assignData(resSP.data.result[0])
		this.decodeResUpload(resSP.data.result[0].sp_location)
		this.decodeOwners(resSP.data.result[0].sp_owner)
		this.decodeBSP(resSP.data.result[0].bsp_license)
		this.decodeBranches(resSP.data.result[0].sp_branch.data)
		this.decodeBod(resSP.data.result[0].sp_bod)

	},
	
	data: () => ({ 
		soleProprietor: true,
		cooperativeHybrid: true,
		corporationModel: true,
		props: ['id'],
		status: '',
		e6: 1,
		businessType: '',
		step1: [],
		step2: [],
		step3: [],
		step4: [],
		step5: [],
		step6: [],
		step7: [],
		step9: [],
		step2form: true,
		step3form: true,
		step4form: true,
		step5form: true,
		step6form: true,
		uploadedBranches: true,
		branchToUpload: true,
		ownerToUpload: true,
		bspToUpload: true,
		bodToUpload: true,
		singleLoc: false,
		is_existing: '',
		steps: ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step9'],
		link: '',
		hasBod: true,
		users: [],
		branch_rate: '',
		page: 1,
		branchPage: [],
		validateAmlaCor: false
	}),
	computed: {
		...mapGetters({
			documents: 'salesProspecting/documents',
			cities: 'salesProspecting/cities',
			countries: 'salesProspecting/countries',
			province: 'salesProspecting/province',
			owners: 'salesProspecting/owners',
			branches: 'salesProspecting/branches',
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
			complianceOfficer: 'salesProspecting/complianceOfficer',
			otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
			existingUploadedFiles: 'salesProspecting/existingUploadedFiles',
			existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
			existingUploadedOwners: 'salesProspecting/existingUploadedOwners',
			spNumber: 'salesProspecting/spNumber',
			bsp: 'salesProspecting/bsp',
			existingBSP: 'salesProspecting/existingBSP',
			single_proprietor_upload: 'salesProspecting/single_proprietor_upload',
			bod: 'salesProspecting/bod',
			token: 'auth/token',
			hrisToken: 'auth/hrisToken',
			authTimestamp: 'auth/authTimestamp',
			primaryDetailsRadio1: 'salesProspecting/primaryDetailsRadio1',
			mother_entity_details: 'salesProspecting/mother_entity_details'
		}),
	},
	methods: {
		countBranchPage(e){
			this.page = 1
			this.branchPage = []
			if(e){
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
		async changePage(e){
			const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('salesProspecting/changePageBranch', {payload})
			if(res.status === 200) {
				this.decodeBranches(res.data.result[0].sp_branch.data)
			}
		},
		decodeBSP (resBSP) {
            resBSP  = JSON.parse(resBSP)
            this.$store.commit('salesProspecting/SET_EXISTING_BSP', resBSP)
		},
		assignData (data) {

			if (data.sp_other_primary_details){
				if(data.sp_other_primary_details.type == 'Single Proprietor'){
					this.hasBod = false
				}
			} else if (data.sp_other_primary_details){
				this.hasBod = true
			}

			this.status = data.status
			var details = {}
			details.created_at  = data.created_at
			details.profile = data.profile
			details.status = data.status
			details.account_name = data.sp_primary_details.account_name
			this.$store.commit('salesProspecting/SET_PRIMARY_DETAILS', details)
			if (data.sp_primary_business_address) {
				this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS', data.sp_primary_business_address)
			}
			if (data.sp_primary_contact_information) {
				this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO', data.sp_primary_contact_information) 
			}
			if (data.sp_compliance_officer_delegate) {
				this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER', data.sp_compliance_officer_delegate)
			}
			if (data.sp_other_primary_details){
				data.sp_other_primary_details.list_of_non_ex_remco = JSON.parse(data.sp_other_primary_details.list_of_non_ex_remco)
				data.sp_other_primary_details.list_of_avp_products = JSON.parse(data.sp_other_primary_details.list_of_avp_products)
				data.sp_other_primary_details.business_information = JSON.parse(data.sp_other_primary_details.business_information)
				data.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(data.sp_other_primary_details.business_type_of_sub_agents )
				data.sp_other_primary_details.top_reasons_for_WU = JSON.parse(data.sp_other_primary_details.top_reasons_for_WU)
				data.sp_other_primary_details.typical_customers = JSON.parse(data.sp_other_primary_details.typical_customers)
				this.businessType = data.sp_other_primary_details.type

				this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS', data.sp_other_primary_details)

				if (this.otherPrimaryDetails.is_rsa == true){
					this.$store.commit('salesProspecting/SET_RADIO1', 'Remittance Service Agent/s (RSA)')
				} 

				if (this.otherPrimaryDetails.is_tie_up == true){
					this.$store.commit('salesProspecting/SET_RADIO1', 'Tie-up')
				}

				if (this.otherPrimaryDetails.is_kya_pending == true){
					this.$store.commit('salesProspecting/SET_RADIO2', 'KYA Pending')
				}

				if (this.otherPrimaryDetails.is_kya_recertification == true){
					this.$store.commit('salesProspecting/SET_RADIO2', 'KYA Recertification')
				}

				if (this.otherPrimaryDetails.is_kya_certified == true){
					this.$store.commit('salesProspecting/SET_RADIO2', 'KYA Certified')
				}
						
			}
		},
		decodeResUpload (resUpload) {
			if (resUpload.length > 0) {
				
				if (resUpload[0].is_western_union == 1){
					this.$store.commit('salesProspecting/SET_WESTERN_MOA', true)
				}
				if (resUpload[0].is_avp == 1){
					this.$store.commit('salesProspecting/SET_AVP_MOA', true)
				}
				if (resUpload[0].is_nonex == 1){
					this.$store.commit('salesProspecting/SET_NONEX_MOA', true)
				}

				var type = resUpload[0].type

				if (type == 'Single Proprietor'){
					this.hasBod = false
				} else {
					this.hasBod = true
				}

				this.businessType = type
				if (type === 'Single Proprietor') {
					resUpload[0].dti = JSON.parse(resUpload[0].dti)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				} else if (type === 'Cooperative/Hybrid') {
					resUpload[0].cda = JSON.parse(resUpload[0].cda)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				} else if (type === 'Corporation') {
					resUpload[0].sec = JSON.parse(resUpload[0].sec)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].gis = JSON.parse(resUpload[0].gis)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				}
				this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_FILES', resUpload[0])
			} else {
				this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
			}
		},
		draftOwners(){
			if (this.otherPrimaryDetails.type != 'Cooperative/Hybrid'){
				this.owners.no_of_members = 0
			}
			this.owners.is_draft = true
			var initialPayload = this.owners
			this.callUploadOwners(initialPayload)
		},
		uploadOwners () {

			if (this.otherPrimaryDetails.type != 'Cooperative/Hybrid'){
				this.owners.no_of_members = 0
			}

			this.$store.commit('salesProspecting/SET_RULE_GENERIC')
			this.$store.commit('salesProspecting/SET_RULE_FILE')
			this.$store.commit('salesProspecting/SET_RULE_EMAIL')
			setTimeout(() => {
				if (this.$refs.ownerToUpload.validate()) {
					var initialPayload = this.owners
					this.owners.is_draft = false
					this.callUploadOwners(initialPayload)
				} else {
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
				}
			})
		},
		async callUploadOwners (initialPayload) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
			await this.$store.dispatch('salesProspecting/doUploadOwner', {payload})
			this.reloadOwners()
			this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
			this.$store.commit('salesProspecting/UNSET_RULE_FILE')
			this.$store.commit('salesProspecting/UNSET_RULE_EMAIL')
		},
		draftBod(){
			this.bod.is_draft = true
			var initialPayload = this.bod
			this.callUploadBOD(initialPayload)
		},
		uploadBOD(){
			this.$store.commit('salesProspecting/SET_RULE_GENERIC')
			this.$store.commit('salesProspecting/SET_RULE_FILE')
			this.$store.commit('salesProspecting/SET_RULE_EMAIL')
			setTimeout(() => {
				if (this.$refs.bodToUpload.validate()) {
					this.bod.is_draft = false
					var initialPayload = this.bod
					this.callUploadBOD(initialPayload)
				} else {
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
				}
			})
		},
		async callUploadBOD (initialPayload) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
			await this.$store.dispatch('salesProspecting/doUploadBod', {payload})
			this.reloadBod()
			this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
			this.$store.commit('salesProspecting/UNSET_RULE_FILE')
			this.$store.commit('salesProspecting/UNSET_RULE_EMAIL')
		},
		uploadBSP () {
			this.$store.commit('salesProspecting/SET_RULE_BSP')
			this.$store.commit('salesProspecting/SET_RULE_FILE')
			this.$store.commit('salesProspecting/SET_RULE_EMAIL')
			setTimeout(() => {
				if (this.$refs.bspToUpload.validate()) {
					var initialPayload = this.bsp
					this.calluploadBSP(initialPayload)
				} else {
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
				}
				
			})
		},
		async calluploadBSP (initialPayload) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
			await this.$store.dispatch('salesProspecting/doUploadBSP', {payload})
			this.reloadBSP()
			this.$store.commit('salesProspecting/SET_BSP_DEFAULT')
			this.$store.commit('salesProspecting/UNSET_RULE_BSP')
			this.$store.commit('salesProspecting/UNSET_RULE_FILE')
			this.$store.commit('salesProspecting/UNSET_RULE_EMAIL')
		},
		uploadBranches () {
			this.$store.commit('salesProspecting/SET_RULE_GENERIC')
			this.$store.commit('salesProspecting/SET_RULE_FILE')
			this.$store.commit('salesProspecting/SET_RULE_EMAIL')
			setTimeout(() => {
				var errDays = []
				this.branches.operating_hours.forEach(function (e) {
					if (!e.isClosed && (e.timeStart === '' || e.timeEnd === '')) {
						errDays.push(e)
					}
				})
				if (errDays.length == 0){
					if (this.$refs.branchToUpload.validate()) {
						this.branches.is_draft = false
						var initialPayload = this.branches
						this.callUploadBranches(initialPayload)
					} else {
						this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
					}
				} else {
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Operation hours not filled up yet!','messagetype':'error'},{ root: true });
					console.log(errDays)
				}
			})
		},
		draftBranch(){
			this.branches.is_draft = true
			var initialPayload = this.branches
			this.callUploadBranches(initialPayload)
		},
		async callUploadBranches (initialPayload) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
			const res = await this.$store.dispatch('salesProspecting/doUploadBranch', {payload})
			if (res.code == 422 || res.code == 400){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
				this.reloadBranches()
			}
			this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
			this.$store.commit('salesProspecting/UNSET_RULE_FILE')
			this.$store.commit('salesProspecting/UNSET_RULE_EMAIL')
		},
		async uploadDocuments () {
			setTimeout(() => {
				if(this.$refs.soleProprietorForm.validate() && this.$refs.cooperativeForm.validate() && this.$refs.corporationForm.validate()){
					var initialPayload = {}
					if (this.businessType === 'Single Proprietor') {
						initialPayload = this.documents['single_proprietor']
						initialPayload.is_western_union = this.documents.is_western_union
						initialPayload.is_avp = this.documents.is_avp
						initialPayload.is_nonex = this.documents.is_nonex
					} else if (this.businessType === 'Cooperative/Hybrid') {
						initialPayload = this.documents['cooperative_hybrid']
						initialPayload.is_western_union = this.documents.is_western_union
						initialPayload.is_avp = this.documents.is_avp
						initialPayload.is_nonex = this.documents.is_nonex
					} else if (this.businessType === 'Corporation') {
						initialPayload = this.documents['corporation']
						initialPayload.is_western_union = this.documents.is_western_union
						initialPayload.is_avp = this.documents.is_avp
						initialPayload.is_nonex = this.documents.is_nonex
					}
					initialPayload.type = this.businessType
					var options = {
						initialFormData: new FormData(),
						howLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};
					var convertedFormData = jsonToFormData(initialPayload, options)
					const payload = {id: this.$route.params.id, payload: convertedFormData}
					this.callUploadDocuments(payload)
				} 
			})
		},
		async callUploadDocuments(payload){
			const res = await this.$store.dispatch('salesProspecting/doUploadFiles', {payload})
			console.log(res)
			this.reloadUploads()
		},
		
		decodeOwners (resOwners) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resOwners.forEach(function(e) {
				e.owner_id = e.id
				e.amlaUploaded = JSON.parse(e.amla)
				e.court_clearanceUploaded = JSON.parse(e.court_clearance)
				e.nbiUploaded = JSON.parse(e.nbi)
				e.picture_2x2Uploaded = JSON.parse(e.picture_2x2)
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
				e.court_clearance = _.clone(nonExpiryFile)
				e.nbi = _.clone(nonExpiryFile)
				e.amla = _.clone(expiryFile)
				e.picture_2x2 = _.clone(nonExpiryFile)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_OWNERS', resOwners)
		},
		decodeBod (resBod){
			let birthdate = {
				menuIssued: false,
                menuExpiry: false
			}
			let nonExpiryFile = {
                file: null
            }
			resBod.forEach(function(e) {
				e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
				e.menu = _.clone(birthdate)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_BOD', resBod)

		},
		decodeBranches (resBranches) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resBranches.forEach(function (e) {
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.justificationUploaded = JSON.parse(e.justification)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.justification = _.clone(nonExpiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(nonExpiryFile)
				})
				e.mailing_country = 'Philippines'
			})
			resBranches.map(coords => {
                if (coords.coordinates.lat){
                    coords.coordinates.lat = parseFloat(coords.coordinates.lat)
                }
                if (coords.coordinates.lng){
                    coords.coordinates.lng = parseFloat(coords.coordinates.lng)
                }
            })

			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
			
		},

		async reloadBranches () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeBranches(res.data.result[0].sp_branch.data)
			this.countBranchPage(res.data.result[0].sp_branch)
			this.$store.commit('salesProspecting/SET_BRANCHES_DEFAULT')
			this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
			this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
			this.save(true)
		},
		async reloadOwners () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeOwners(res.data.result[0].sp_owner)
			this.$store.commit('salesProspecting/SET_OWNERS_DEFAULT')
			this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
			this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
			this.save(true)
		},
		async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.$store.commit('salesProspecting/SET_DOCUMENTS_DEFAULT')
			this.decodeResUpload(res.data.result[0].sp_location)
			this.save(true)
		},
		async reloadBSP () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeBSP(res.data.result[0].bsp_license)
			this.save(true)
		},
		async reloadBod () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeBod(res.data.result[0].sp_bod)
			this.$store.commit('salesProspecting/SET_BOD_DEFAULT')
			this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
			this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
			this.save(true)
		},
		save (isDraft) {
			const initialPayload = {
				is_draft: isDraft,
				type: this.businessType,
				primary_business_address: this.primaryBusinessAddress,
				primary_contact_information: this.primaryContactInfo,
				compliance_officer_delegate: this.complianceOfficer,
				other_details: this.otherPrimaryDetails,
				is_mother_entity: this.mother_entity_details.is_mother_entity,
				sub_entities: this.mother_entity_details.sub_entities
			}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			if (isDraft) {
				this.callSave(payload)
			} else {
				const self = this
				self.$store.commit('salesProspecting/SET_RULE_GENERIC')
				self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
				self.$store.commit('salesProspecting/SET_RULE_EMAIL')
				setTimeout(() => {
					var step1Validated = false
					var step6Validated = false
					var step7Validated = false
					var step9Validated = false
					self.steps.forEach(e => {
						if (e === 'step1') {
							if (self.existingUploadedFiles.length === 0) {
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'No files uploaded!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
							} else {

								let array = [
									'Bank',
									'Financial Services',
									'Currency Exchange',
									'Money Lender',
									'Pawnshop',
									'Money Transmitter',
									'Cash in Services'
								]

								let businessType = this.otherPrimaryDetails.business_type_of_sub_agents
								if (Object.keys(businessType).length === 0){
									this.validateAmlaCor = false
									console.log(this.validateAmlaCor)
								} else {
									for (let i = 0; i < businessType.length; i++) {
										if (array.includes(businessType[i])) {
											this.validateAmlaCor = true
											console.log(this.validateAmlaCor)
											break
										} else {
											this.validateAmlaCor = false
											console.log(this.validateAmlaCor)
										}
									}
								}

								if (this.validateAmlaCor){
									if (self.businessType === '') {
										self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are some fields that have not been filled out!','messagetype':'error'},{ root: true });
										self[e] = [() => false]
									} else if (self.businessType === 'Single Proprietor') {
										if (self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.dti && self.existingUploadedFiles.cmap && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.fss_deed_of_undertaking && self.existingUploadedFiles.fss_pdaf && self.existingUploadedFiles.fs_itr && (self.existingUploadedFiles.mother_of_contract || self.existingUploadedFiles.moa_nonex || self.existingUploadedFiles.moa_avp) && self.existingUploadedFiles.amla_questionnaire && self.existingUploadedFiles.amla_cor) {
											step1Validated = true
											console.log('validated single')
										} else {
											self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
											self[e] = [() => false]
											console.log('not validated single')
										}
									} else if (self.businessType === 'Cooperative/Hybrid') {
										console.log('coop')
										if (self.existingUploadedFiles.cda && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.org_chart && self.existingUploadedFiles.board_resolution && self.existingUploadedFiles.cmap && self.existingUploadedFiles.secretary_certificate && self.existingUploadedFiles.fss_deed_of_undertaking && self.existingUploadedFiles.fss_pdaf && self.existingUploadedFiles.fs_itr && (self.existingUploadedFiles.mother_of_contract || self.existingUploadedFiles.moa_nonex || self.existingUploadedFiles.moa_avp) && self.existingUploadedFiles.amla_questionnaire && self.existingUploadedFiles.amla_cor) {
											step1Validated = true
										} else {
											self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
											self[e] = [() => false]
										}
									} else if (self.businessType === 'Corporation') {
										console.log('corp')
										if (self.existingUploadedFiles.sec && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.org_chart && self.existingUploadedFiles.board_resolution && self.existingUploadedFiles.gis && self.existingUploadedFiles.cmap && self.existingUploadedFiles.secretary_certificate && self.existingUploadedFiles.fss_deed_of_undertaking && self.existingUploadedFiles.fss_pdaf && self.existingUploadedFiles.fs_itr && (self.existingUploadedFiles.mother_of_contract || self.existingUploadedFiles.moa_nonex || self.existingUploadedFiles.moa_avp) && self.existingUploadedFiles.amla_questionnaire && self.existingUploadedFiles.amla_cor) {
											step1Validated = true
										} else {
											self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
											self[e] = [() => false]
										}
									}
								} else {
									if (self.businessType === '') {
										self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are some fields that have not been filled out!','messagetype':'error'},{ root: true });
										self[e] = [() => false]
									} else if (self.businessType === 'Single Proprietor') {
										if (self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.dti && self.existingUploadedFiles.cmap && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.fss_deed_of_undertaking && self.existingUploadedFiles.fss_pdaf && self.existingUploadedFiles.fs_itr && (self.existingUploadedFiles.mother_of_contract || self.existingUploadedFiles.moa_nonex || self.existingUploadedFiles.moa_avp) && self.existingUploadedFiles.amla_questionnaire) {
											step1Validated = true
											console.log('validated single')
										} else {
											self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
											self[e] = [() => false]
											console.log('not validated single')
										}
									} else if (self.businessType === 'Cooperative/Hybrid') {
										console.log('coop')
										if (self.existingUploadedFiles.cda && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.org_chart && self.existingUploadedFiles.board_resolution && self.existingUploadedFiles.cmap && self.existingUploadedFiles.secretary_certificate && self.existingUploadedFiles.fss_deed_of_undertaking && self.existingUploadedFiles.fss_pdaf && self.existingUploadedFiles.fs_itr && (self.existingUploadedFiles.mother_of_contract || self.existingUploadedFiles.moa_nonex || self.existingUploadedFiles.moa_avp) && self.existingUploadedFiles.amla_questionnaire) {
											step1Validated = true
										} else {
											self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
											self[e] = [() => false]
										}
									} else if (self.businessType === 'Corporation') {
										console.log('corp')
										if (self.existingUploadedFiles.sec && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.org_chart && self.existingUploadedFiles.board_resolution && self.existingUploadedFiles.gis && self.existingUploadedFiles.cmap && self.existingUploadedFiles.secretary_certificate && self.existingUploadedFiles.fss_deed_of_undertaking && self.existingUploadedFiles.fss_pdaf && self.existingUploadedFiles.fs_itr && (self.existingUploadedFiles.mother_of_contract || self.existingUploadedFiles.moa_nonex || self.existingUploadedFiles.moa_avp) && self.existingUploadedFiles.amla_questionnaire) {
											step1Validated = true
										} else {
											self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
											self[e] = [() => false]
										}
									}
								}
							}
						} else if (e === 'step6') {
							console.log('step6')
							if (self.existingUploadedBranches.length === 0) {
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'You need to add at least 1 branch!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
							} else if (self.existingUploadedBranches.length != 0){
								console.log(this.branch_rate)
								// self.existingUploadedBranches.forEach((element) => {
								// 	if(element.is_draft == true){
								// 		self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Draft branch cannot be submitted!','messagetype':'error'},{ root: true });
								// 		self[e] = [() => false]
								// 	} else {
								// 		self[e] = []
								// 		step6Validated = true
								// 	}
								// })
								if(this.branch_rate != 100){
									self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Draft branch cannot be submitted!','messagetype':'error'},{ root: true });
									self[e] = [() => false]
								} else {
									self[e] = []
									step6Validated = true
								}
							}
						} else if (e === 'step7') {
							console.log('step7')
							if (self.existingUploadedOwners.length === 0) {
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'You need to add at least 1 owner!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
							} else if (self.existingUploadedOwners.length != 0){
								self.existingUploadedOwners.forEach((element) => {
									if(element.is_draft == true){
										self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Draft owner cannot be submitted!','messagetype':'error'},{ root: true });
										self[e] = [() => false]
									} else {
										self[e] = []
										step7Validated = true
									}
								})
							}
						}
						else if (e === 'step9') {
							console.log('step9')
							if (this.is_existing === 1){
								if (self.existingBSP === null) {
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Upload BSP License!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
								} else {
									self[e] = []
									step9Validated = true
								}
							} else if (this.is_existing === 0){
								step9Validated = true
							}
						}
						else {
							if (!self.$refs[e].validate()) {
								self[e] = [() => false]
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
							} else {
								self[e] = []
							}
						}
					})
					console.log(step1Validated, 'step 1 validated')
					if (self.$refs.step3.validate() && self.$refs.step4.validate() && step1Validated && step6Validated && step7Validated && step9Validated) {
						self.steps.forEach(e => {
							self[e] = []
						})
						this.callSubmit(payload)
					}
				})
			}
		},
		async callSave (payload) {
			const res = await this.$store.dispatch('salesProspecting/doUpdateSalesProspecting', {payload})
			if(res.status === 200) {
				console.log('save as draft')
			} else if (res.code == 422 || res.code == 400){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            }
		},
		async callSubmit (payload) {
			const res = await this.$store.dispatch('salesProspecting/doUpdateSalesProspecting', {payload})
			if(res.status === 200) {
				let link = this.link
				this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
				this.$store.commit('salesProspecting/UNSET_RULE_CHECKBOX')
				this.$store.commit('salesProspecting/UNSET_RULE_SCORECARD')
				this.$store.commit('salesProspecting/SET_BRANCHES_DEFAULT')
				this.$store.commit('salesProspecting/SET_OWNERS_DEFAULT')
				this.$router.push(link);
			} else if (res.code == 422 || res.code == 400){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            }
		}
	}
};
</script>

<style scoped>
	.stepper-step-proceed {
		cursor: pointer;
	}
</style>