<template>
	<div class="mt-1">
		<div>
			<v-row class="mb-3">
				<v-col cols="3">
					<v-text-field
						label="*First Name"
						hide-details="auto"
						v-model="primaryContactInfo.first_name"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
										
				<v-col cols="3">
					<v-text-field
						label="*Middle Name"
						hide-details="auto"
						v-model="primaryContactInfo.middle_name"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
				
				<v-col cols="3">
					<v-text-field
						label="*Last Name"
						hide-details="auto"
						v-model="primaryContactInfo.last_name"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
										
				<v-col cols="3">
					<v-text-field
						label="Suffix"
						hide-details="auto"
						v-model="primaryContactInfo.suffix"
						outlined
						dense
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
									
			<v-row>
				<v-col>
					<h4>Primary Contact Address</h4>
				</v-col>
			</v-row>

			<v-checkbox
				label="Same As Primary Address"
				v-model="primaryContactInfo.is_same_primary"
				@click="sameAddress"
				:disabled="checkAddress() || disabled"
				>
			</v-checkbox>
			
			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 1"
						hide-details="auto"
						v-model="primaryContactInfo.address_line_1"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
									
			<v-row>
				<v-col>
					<v-text-field
						label="*Address Line 2"
						hide-details="auto"
						v-model="primaryContactInfo.address_line_2"
						outlined
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
									
			<v-row>
				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						label="*Country"
						outlined
						item-text="country"
						item-value="country"
						hide-details="auto"
						v-model="primaryContactInfo.country"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						v-if="disabled == true"
						label="*Province"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.province"
						dense
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Province"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.province"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						@change="getRegion"
						:items="province"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || primaryContactCity.length == 0"
						label="*City"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.city"
						dense
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*City"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.city"
						dense
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						:items="primaryContactCity"
						@change="getZip"
						>
					</v-autocomplete>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
					>
					<v-text-field
						label="*Region"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.region"
						dense
						readonly
						:rules="genericRule"
						>
					</v-text-field>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="2"
					>
					<v-text-field
						v-if="disabled == true || primaryContactZip.length == 0"
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.postal_code"
						dense
						type="number"
						:rules="genericRule"
						readonly
						>
					</v-text-field>
					<v-autocomplete
						v-else
						label="*Postal Code"
						outlined
						hide-details="auto"
						v-model="primaryContactInfo.postal_code"
						dense
						type="number"
						:rules="genericRule"
						hide-spin-buttons
						:readonly="disabled"
						:items="primaryContactZip"
						>
					</v-autocomplete>
				</v-col>
			</v-row>
		</div>
		
		<div>
			<v-row>
				<v-col>
					<h4>Contact Details</h4>
				</v-col>
			</v-row>
									
			<v-row>
				<v-col cols="4">
					<v-text-field
						label="Landline Number"
						v-model="primaryContactInfo.landline"
						outlined
						hide-details="auto"
						dense
						type="number"
						hide-spin-buttons
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
										
				<v-col cols="4">
					<v-text-field
						label="*Cellphone Number"
						v-model="primaryContactInfo.cellphone"
						outlined
						hide-details="auto"
						dense
						type="number"
						hide-spin-buttons
						:rules="genericRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
				
				<v-col cols="4">
					<v-text-field
						label="*Email"
						v-model="primaryContactInfo.email_address"
						outlined
						hide-details="auto"
						dense
						:rules="emailRule"
						:readonly="disabled"
						>
					</v-text-field>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ['disabled'],
    computed: {
		...mapGetters({
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
			genericRule: 'salesProspecting/genericRule',
			emailRule: 'salesProspecting/emailRule',
			province: 'salesProspecting/province',
			primaryContactCity: 'salesProspecting/primaryContactCity',
			primaryContactZip: 'salesProspecting/primaryContactZip',
			hrisToken: 'auth/hrisToken',
		}),
	},
	methods:{
		checkAddress () {
			if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '' && this.primaryBusinessAddress.cellphone !== '') {
				return false
			} else {
				return true
			}
		},
		sameAddress () {
			if (this.primaryContactInfo.is_same_primary){
				console.log('check')
				this.primaryContactInfo.is_same_primary = true
				this.primaryContactInfo.address_line_1 = this.primaryBusinessAddress.address_line_1
				this.primaryContactInfo.address_line_2 = this.primaryBusinessAddress.address_line_2
				this.primaryContactInfo.country = this.primaryBusinessAddress.country
				this.primaryContactInfo.city = this.primaryBusinessAddress.city
				this.primaryContactInfo.region = this.primaryBusinessAddress.region
				this.primaryContactInfo.postal_code = this.primaryBusinessAddress.postal_code
				this.primaryContactInfo.province = this.primaryBusinessAddress.province
				this.primaryContactInfo.landline = this.primaryBusinessAddress.landline
				this.primaryContactInfo.cellphone = this.primaryBusinessAddress.cellphone
			} else {
				console.log('uncheck')
				console.log(this.primaryContactInfo.is_same_primary)
				this.primaryContactInfo.address_line_1 = ''
				this.primaryContactInfo.address_line_2 = ''
				this.primaryContactInfo.country = 'Philippines'
				this.primaryContactInfo.city = ''
				this.primaryContactInfo.province = ''
				this.primaryContactInfo.region = ''
				this.primaryContactInfo.postal_code = ''
				this.primaryContactInfo.landline = ''
				this.primaryContactInfo.cellphone = ''
			}
		},
		async getRegion(province){
			this.primaryContactInfo.postal_code = ''
			this.primaryContactInfo.city = ''
			const payload = {
				payload : province,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetPrimaryContactRegion', {payload})
			const finalPayload = {
				payload : this.primaryContactInfo.region,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGePrimaryContactCity', {finalPayload})
		},
		async getZip(city){
			const payload = {
				payload : this.primaryContactInfo.region,
				city : city,
				token: this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetPrimaryContactZip', {payload})
		},
	}
};
</script>
