<template>
	<div>			
		<br>			
		<v-row>
            <v-col :cols="6">
                <v-file-input
                    v-model="bsp.bsp_license.file"
                    label="*BSP License"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :rules="fileRule"
                    >
                </v-file-input>
            </v-col>
    
            <v-col :cols="1" v-if="existingBSP" class="mt-2">
                <v-icon
                    @click="retrieveUploaded(existingBSP)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
		</v-row>
    </div>
									
			
</template>
<script>
import { mapGetters } from 'vuex';
// import jsonToFormData from 'json-form-data';
export default {
    props: ['disabled'],
	data () {
		return {
			sameAdd: false,
		};
	},
    computed: {
		...mapGetters({
            countries: 'salesProspecting/countries',
            cities: 'salesProspecting/cities',
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
            genericRule: 'salesProspecting/genericRule',
            fileRule: 'salesProspecting/fileRule',
            bsp : 'salesProspecting/bsp',
            existingBSP : 'salesProspecting/existingBSP',
            bspRule : 'salesProspecting/bspRule'
		}),
	},
  
    methods: {
        returnDateIssued (item) {
            if (item[0])
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            return max
        },

		async retrieveUploaded (item) {
            
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            console.log(max)
			const payload = {
				file: max.url,
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
                console.log()
				blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
    }
};
</script>
