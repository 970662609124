<template>
    <div class="mt-5">
        <v-row class="mb-3">
            <v-col cols="3">
                <v-text-field
                    label="*First Name"
                    hide-details="auto"
                    v-model="bod.first_name"
                    outlined
                    dense
                    :disabled="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    label="*Middle Name"
                    hide-details="auto"
                    v-model="bod.middle_name"
                    outlined
                    dense
                    :disabled="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    label="*Last Name"
                    hide-details="auto"
                    v-model="bod.last_name"
                    outlined
                    dense
                    :disabled="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    label="Suffix"
                    hide-details="auto"
                    v-model="bod.suffix"
                    outlined
                    dense
                    :disabled="disabled"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-text-field
                    label="*Number of Shares"
                    hide-details="auto"
                    v-model="bod.no_of_shares"
                    outlined
                    dense
                    type="number"
                    hide-spin-buttons
                    :disabled="disabled"
                    @change="generateID"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    label="*Position"
                    hide-details="auto"
                    v-model="bod.position"
                    outlined
                    dense
                    :disabled="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>

            <v-col :cols="3">
                <v-menu
                    v-model="bod.dob.menuIssued"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="bod.date_of_birth"
                            label="*Date of Birth"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="genericRule"
                            dense
                            outlined
                            hide-details="auto"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="bod.date_of_birth"
                        @input="bod.dob.menuIssued = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>



        </v-row>
        <v-row >
			<v-col cols="12">
				<v-checkbox
					label="Is Familial"
					v-model="bod.is_familial"
					hide-details="auto"
					:readonly="disabled"
					>
				</v-checkbox>
			</v-col> 
        </v-row>

        <v-row v-if="bod.is_familial == true || showID == true">
            <v-col :cols="9">
                <v-file-input
                    v-model="bod.valid_id.file"
                    label="*Valid ID"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    :rules="fileRule"
                    >
                    </v-file-input>
            </v-col>
            <!-- <v-col :cols="3">
                <v-menu
                    v-model="bod.valid_id.menuIssued"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    hide-details="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="bod.valid_id.date_issued"
                            label="Date Issued"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="!bod.valid_id.file  ? '' : on"
                            dense
                            outlined
                            hide-details="auto"
                            :rules="genericRule"
                            >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="bod.valid_id.date_issued"
                        @input="bod.valid_id.menuIssued = false"
                    
                        >
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col :cols="3">
                <v-menu
                    v-model="bod.valid_id.menuExpiry"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    hide-details="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="bod.valid_id.date_expiry"
                            label="Expiry Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="!bod.valid_id.date_issued  ? '' : on"
                            dense
                            outlined
                            hide-details="auto"
                            :rules="genericRule"
                            >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="bod.valid_id.date_expiry"
                        @input="bod.valid_id.menuExpiry = false"
                        hide-details="auto"
                        >
                    </v-date-picker>
                </v-menu>
            </v-col> -->
        </v-row>

        <v-row>
            <v-col>
                <h4>Primary Contact Address</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    label="*Address Line 1"
                    hide-details="auto"
                    v-model="bod.address_line_1"
                    outlined
                    dense
                    :disabled="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field
                    label="*Address Line 2"
                    hide-details="auto"
                    v-model="bod.address_line_2"
                    outlined
                    dense
                    :disabled="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="2"
                >
                <v-text-field
                    label="*Country"
                    readonly
                    item-text="country"
                    item-value="country"
                    v-model="bod.country"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="disabled"
                >
                </v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="3"
                >
                <v-text-field
                    v-if="disabled == true"
                    label="*Province"
                    outlined
                    hide-details="auto"
                    v-model="bod.province"
                    dense
                    :rules="genericRule"
                    :readonly="disabled"
                    >
                </v-text-field>
                <v-autocomplete
                    v-else
                    label="*Province"
                    outlined
                    hide-details="auto"
                    v-model="bod.province"
                    dense
                    :rules="genericRule"
                    hide-spin-buttons
                    :readonly="disabled"
                    @change="getRegion"
                    :items="province"
                    >
                </v-autocomplete>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="2"
                >
                <v-text-field
                    v-if="disabled == true || bodCity.length == 0"
                    label="*City"
                    outlined
                    hide-details="auto"
                    v-model="bod.city"
                    dense
                    :rules="genericRule"
                    readonly
                    >
                </v-text-field>
                <v-autocomplete
                    v-else
                    label="*City"
                    outlined
                    hide-details="auto"
                    v-model="bod.city"
                    dense
                    :rules="genericRule"
                    hide-spin-buttons
                    :readonly="disabled"
                    :items="bodCity"
                    @change="getZip"
                    >
                </v-autocomplete>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="*Region"
                    outlined
                    hide-details="auto"
                    v-model="bod.region"
                    dense
                    readonly
                    :rules="genericRule"
                    >
                </v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="2"
                >
                <v-text-field
                    v-if="disabled == true || bodZip.length == 0"
                    label="*Postal Code"
                    outlined
                    hide-details="auto"
                    v-model="bod.postal_code"
                    dense
                    type="number"
                    :rules="genericRule"
                    readonly
                    >
                </v-text-field>
                <v-autocomplete
                    v-else
                    label="*Postal Code"
                    outlined
                    hide-details="auto"
                    v-model="bod.postal_code"
                    dense
                    type="number"
                    :rules="genericRule"
                    hide-spin-buttons
                    :items="bodZip"
                    :readonly="disabled"
                    >
                </v-autocomplete>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';


export default {
    props: ['disabled'],
    data () {
		return {
            showID: null
        }
	},
    computed: {
		...mapGetters({
            bod: 'salesProspecting/bod',
            genericRule: 'salesProspecting/genericRule',
            fileRule: 'salesProspecting/fileRule',
            emailRule: 'salesProspecting/emailRule',
            province: 'salesProspecting/province',
            bodCity: 'salesProspecting/bodCity',
            bodZip: 'salesProspecting/bodZip',
            hrisToken: 'auth/hrisToken',
		})
	},
    methods: {
        generateID(value){
            let newValue =  (parseInt(value))
            if (newValue >= 10){
                this.showID = true
            } else {
                this.showID = false
            }
        },
        async getRegion(province){
			this.bod.postal_code = ''
			this.bod.city = ''
			const payload = {
				payload : province,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetBodRegion', {payload})

			const finalPayload = {
				payload : this.bod.region,
				token : this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetBodCity', {finalPayload})
		},
		async getZip(city){
            console.log(city)
			const payload = {
				payload : this.bod.region,
				city : city,
				token: this.hrisToken
			}
			await this.$store.dispatch('salesProspecting/doGetBodZip', {payload})
		},
    }
}
</script>
