<template>
    <div class="mb-5">
        <v-expansion-panels class="mb-5">
            <v-expansion-panel v-for="(parentItem, parentIndex) in existingUploadedOwners" :key="parentIndex">
                <v-expansion-panel-header>
                    <div>
                        <v-icon :disabled="disabled" @click.stop="" @click="deleteOwner(parentItem)" color="error" y>mdi-delete</v-icon>
                        &ensp;
                        <span v-if="parentItem.is_draft == true">{{parentItem.first_name}} {{parentItem.middle_name}} {{parentItem.last_name}} (Draft) </span>
                        <span v-if="parentItem.is_draft == false">{{parentItem.first_name}} {{parentItem.middle_name}} {{parentItem.last_name}}</span>
                    </div>
                </v-expansion-panel-header>
                <v-form ref="uploadedOwner" v-model="uploadedOwner">
                    <v-expansion-panel-content eager>
                        <v-row class="mb-3">
                            <v-col cols="3">
                                <v-text-field
                                    label="*First Name"
                                    hide-details="auto"
                                    v-model="parentItem.first_name"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                    >
                                </v-text-field>
                                </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Middle Name"
                                    hide-details="auto"
                                    v-model="parentItem.middle_name"
                                    outlined
                                    dense
                                    :rules="existingGenericRule"
                                    :readonly="disabled"
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="*Last Name"
                                    hide-details="auto"
                                    v-model="parentItem.last_name"
                                    outlined
                                    dense
                                    :readonly="disabled"
                                    :rules="existingGenericRule"
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                    <v-text-field
                                        label="Suffix"
                                        hide-details="auto"
                                        v-model="parentItem.suffix"
                                        outlined
                                        dense
                                        :readonly="disabled"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field
                                        label="*Percentage of Ownership"
                                        hide-details="auto"
                                        v-model="parentItem.percentage_ownership"
                                        outlined
                                        dense
                                        :readonly="disabled"
                                        :rules="existingGenericRule"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3" v-if="otherPrimaryDetails.type == 'Cooperative/Hybrid'">
                                    <v-text-field
                                        label="*No of Members"
                                        hide-details="auto"
                                        v-model="parentItem.no_of_members"
                                        outlined
                                        type="number"
                                        hide-spin-buttons
                                        dense
                                        :disabled="disabled"
                                        :rules="genericRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <h4>Primary Contact Address</h4>
                                </v-col>
                            </v-row>

                            <v-checkbox
                                label="Same As Primary Address"
                                v-model="parentItem.is_same_primary"
                                @click="sameAddress(parentItem)"
                                :disabled="checkAddress() || disabled"
                                >
                            </v-checkbox>

                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="*Address Line 1"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_1"
                                        outlined
                                        dense
                                        :readonly="disabled"
                                        :rules="existingGenericRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="*Address Line 2"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_2"
                                        outlined
                                        dense
                                        :readonly="disabled"
                                        :rules="existingGenericRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        label="*Country"
                                        item-text="country"
                                        item-value="country"
                                        v-model="parentItem.country"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        readonly
                                        :rules="existingGenericRule"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        v-if="disabled == true"
                                        label="*Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        dense
                                        :rules="existingGenericRule"
                                        :readonly="disabled"
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*Province"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        dense
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled"
                                        @change="getRegion"
                                        @click="getProvinceIndex(parentIndex)"
                                        :items="province"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingOwnersCity[parentIndex]"
                                        label="*City"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        dense
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*City"
                                        outlined
                                        no-filter
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        dense
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :readonly="disabled"
                                        :items="existingOwnersCity[parentIndex]"
                                        @change="getZip"
                                        >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    >
                                    <v-text-field
                                        label="*Region"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.region"
                                        dense
                                        readonly
                                        :rules="existingGenericRule"
                                        >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="2"
                                    >
                                    <v-text-field
                                        v-if="disabled == true || !existingOwnersZip[parentIndex]"
                                        label="*Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        readonly
                                        >
                                    </v-text-field>
                                    <v-autocomplete
                                        v-else
                                        label="*Postal Code"
                                        outlined
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        dense
                                        type="number"
                                        :rules="existingGenericRule"
                                        hide-spin-buttons
                                        :items="existingOwnersZip[parentIndex]"
                                        :readonly="disabled"
                                        >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Landline Number"
                                        v-model="parentItem.landline"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        hide-spin-buttons
                                        :readonly="disabled"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="*Cellphone Number"
                                        v-model="parentItem.cellphone"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        hide-spin-buttons
                                        :readonly="disabled"
                                        :rules="existingGenericRule"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="*Email"
                                        v-model="parentItem.email_address"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :readonly="disabled"
                                        :rules="existingEmailRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <b>Documents</b>
                                </v-col>
                            </v-row>

                            <!-- AMLA -->
                            <v-row v-if="parentItem.amlaUploaded">
                                <v-col :cols="3">
                                    <v-file-input
                                        v-model="parentItem.amla.file"
                                        label="*AMLA"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.amla.menuIssued"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.amla.date_issued"
                                                    label="*Date Issued"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="!parentItem.amla.file  ? '' : on"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :disabled="disabled"
                                                    :rules="checkRule(parentItem.amla)"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.amla.date_issued"
                                            @input="parentItem.amla.menuIssued = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.amla.menuExpiry"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.amla.date_expiry"
                                                label="*Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!parentItem.amla.date_issued  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :disabled="disabled"
                                                :rules="checkRule(parentItem.amla)"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.amla.date_expiry"
                                            @input="parentItem.amla.menuExpiry = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.amlaUploaded">
                                    <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="retrieveUploaded(parentItem.amlaUploaded)"
                                            >
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span v-if="parentItem.amlaUploaded">Date Issued: {{returnDateIssued(parentItem.amlaUploaded).date_issued}}</span><br>
                                    <span v-if="parentItem.amlaUploaded">Expiry Date: {{returnDateIssued(parentItem.amlaUploaded).date_expiry}}</span><br>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col :cols="3">
                                    <v-file-input
                                        v-model="parentItem.amla.file"
                                        label="*AMLA"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        :rules="existingFileRUle"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.amla.menuIssued"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.amla.date_issued"
                                                    label="*Date Issued"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="!parentItem.amla.file  ? '' : on"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :disabled="disabled"
                                                    :rules="checkRule(parentItem.amla)"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.amla.date_issued"
                                            @input="parentItem.amla.menuIssued = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="3">
                                    <v-menu
                                        v-model="parentItem.amla.menuExpiry"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        hide-details="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="parentItem.amla.date_expiry"
                                                label="*Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="!parentItem.amla.date_issued  ? '' : on"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                :disabled="disabled"
                                                :rules="checkRule(parentItem.amla)"
                                                >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="parentItem.amla.date_expiry"
                                            @input="parentItem.amla.menuExpiry = false"
                                            hide-details="auto"
                                            >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.amlaUploaded">
                                    <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="retrieveUploaded(parentItem.amlaUploaded)"
                                            >
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span v-if="parentItem.amlaUploaded">Date Issued: {{returnDateIssued(parentItem.amlaUploaded).date_issued}}</span><br>
                                    <span v-if="parentItem.amlaUploaded">Expiry Date: {{returnDateIssued(parentItem.amlaUploaded).date_expiry}}</span><br>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            
                            <!-- Valid ID -->
                            <v-row v-if="parentItem.valid_idUploaded">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.valid_id.file"
                                        label="*Valid ID"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.valid_idUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.valid_idUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.valid_id.file"
                                        label="*Valid ID"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        :rules="existingFileRUle"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.valid_idUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.valid_idUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>

                            <!-- NBI -->
                            <v-row v-if="parentItem.nbiUploaded">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.nbi.file"
                                        label="*NBI"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                              
                                <v-col :cols="1" class="mt-1" v-if="parentItem.nbiUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.nbiUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.nbi.file"
                                        label="*NBI"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        :rules="existingFileRUle"
                                        >
                                    </v-file-input>
                                </v-col>
                              
                                <v-col :cols="1" class="mt-1" v-if="parentItem.nbiUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.nbiUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>

                            <!-- 2x2 -->
                            <v-row v-if="parentItem.picture_2x2Uploaded">
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.picture_2x2.file"
                                        label="*2x2"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.picture_2x2Uploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.picture_2x2Uploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.picture_2x2.file"
                                        label="*2x2"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        :rules="existingFileRUle"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.picture_2x2Uploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.picture_2x2Uploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>

                            <!-- Court Clearance -->
                            <v-row >
                                <v-col :cols="9">
                                    <v-file-input
                                        v-model="parentItem.court_clearance.file"
                                        label="Court Clearance"
                                        prepend-icon="mdi-paperclip"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :disabled="disabled"
                                        >
                                    </v-file-input>
                                </v-col>
                                <v-col :cols="1" class="mt-1" v-if="parentItem.court_clearanceUploaded">
                                    <v-icon
                                        @click="retrieveUploaded(parentItem.court_clearanceUploaded)"
                                        >
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                            </v-row>

                            <v-row>
                            <!-- NOT DRAFT BUTTON -->
                                <v-col v-if="!disabled && parentItem.is_draft == false">
                                    <v-btn outlined v-if="!disabled" color="primary" style="float:right;" @click="updateOwner(parentItem, parentIndex)">
                                        Update Owner
                                    </v-btn>
                                </v-col>
                                <!-- DRAFT BUTTON -->
                                <v-col v-if="!disabled && parentItem.is_draft == true">
                                    <v-btn class="ml-3" v-if="!disabled" color="primary" style="float:right;" @click="updateOwner(parentItem, parentIndex)">
                                        Add Owner
                                    </v-btn>
                                    <v-btn outlined v-if="!disabled" color="primary" style="float:right;" @click="updateDraft(parentItem, parentIndex)">
                                        Update Owner Draft
                                    </v-btn>
                                </v-col>
                            
                            </v-row>



                    </v-expansion-panel-content>
                </v-form>
            </v-expansion-panel>
        </v-expansion-panels>

            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="400"
                >
                <v-card>
                    <v-card-title>
                        Delete Owner
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <p style="font-size: 15px;">Delete {{dialogDescription}}? You can't undo this.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelDialog"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmDialog"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
import _ from 'lodash';
export default {
    props: ['disabled'],
    data: () => ({ 
        item: '',
        dialog: false,
        uploadedOwner: true,
        currentIndex: '',
        deleteDialog: false,
        ownerId: '',
        dialogDescription: ''
    }),
    methods: { 
        sameAddress(item){
            if (item.is_same_primary){
				console.log('check')
				item.is_same_primary = true
				item.address_line_1 = this.primaryBusinessAddress.address_line_1
				item.address_line_2 = this.primaryBusinessAddress.address_line_2
				item.country = this.primaryBusinessAddress.country
				item.city = this.primaryBusinessAddress.city
				item.region = this.primaryBusinessAddress.region
				item.postal_code = this.primaryBusinessAddress.postal_code
				item.province = this.primaryBusinessAddress.province
				item.landline = this.primaryBusinessAddress.landline
				item.cellphone = this.primaryBusinessAddress.cellphone
			} else {
				console.log('uncheck')
				item.address_line_1 = ''
				item.address_line_2 = ''
				item.country = 'Philippines'
				item.city = ''
				item.province = ''
				item.region = ''
				item.postal_code = ''
				item.landline = ''
				item.cellphone = ''
			}
        },
        checkAddress () {
			if (this.primaryBusinessAddress.address_line_1 !== '' && this.primaryBusinessAddress.address_line_2 !== '' && this.primaryBusinessAddress.country !== '' && this.primaryBusinessAddress.city !== '' && this.primaryBusinessAddress.region !== '' && this.primaryBusinessAddress.postal_code !== '' && this.primaryBusinessAddress.cellphone !== '') {
				return false
			} else {
				return true
			}
		},
        getProvinceIndex(index){
            this.currentIndex = index
        },
        async getRegion(province){
			this.existingUploadedOwners[this.currentIndex].postal_code = ''
			this.existingUploadedOwners[this.currentIndex].city = ''
			const payload = {
				payload : province,
				token : this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingOwnersRegion', {payload})

			const finalPayload = {
				payload : this.existingUploadedOwners[this.currentIndex].region,
				token : this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingOwnersCity', {finalPayload})
		},
		async getZip(city){
			const payload = {
				payload : this.existingUploadedOwners[this.currentIndex].region,
				city : city,
				token: this.hrisToken,
                index: this.currentIndex
			}
			await this.$store.dispatch('salesProspecting/doGetExistingOwnersZip', {payload})
		},
        async deleteOwner(item){
            this.deleteDialog = true
            this.ownerId = item.owner_id
            this.dialogDescription = item.first_name
        },
        async confirmDialog(){
            const payload = {id: this.$route.params.id, owner_id: this.ownerId}
            const res = await this.$store.dispatch('salesProspecting/doDeleteOwner', {payload})
            if (res.status == 200){
                this.deleteDialog = false
                this.ownerId = ''
                this.dialogDescription = ''
                this.reloadOwners()
            }
        },
        cancelDialog(){
            this.deleteDialog = false
            this.ownerId = ''
            this.dialogDescription = ''
        },
        checkRule (item) {
            if (item.file) {
                return [v => !!v || 'This field is required']
            }
        },
        returnDateIssued (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            return max
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
        updateDraft(item){
            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function(value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1': '0';
                    }
                    return value;
                }
            }
            let initialPayload = {...item, is_draft: true}
            delete initialPayload.id
            delete initialPayload.sales_prospects_id
            delete initialPayload.updated_at
            delete initialPayload.created_at
            if (initialPayload.valid_idUploaded) {
                delete initialPayload.valid_idUploaded
            }
            if (initialPayload.amlaUploaded) {
                delete initialPayload.amlaUploaded
            }
            if (initialPayload.court_clearanceUploaded) {
                delete initialPayload.court_clearanceUploaded
            }
            if (initialPayload.nbiUploaded) {
                delete initialPayload.nbiUploaded
            }
            if (initialPayload.picture_2x2Uploaded) {
                delete initialPayload.picture_2x2Uploaded
            }
            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = {id: this.$route.params.id, payload: convertedFormData}
            this.callUpdateOwner(payload)
        },
        updateOwner (item, index) {
            const self = this
            self.$store.commit('salesProspecting/SET_RULE_GENERIC_EXISTING')
			self.$store.commit('salesProspecting/SET_RULE_EMAIL_EXISTING')
            self.$store.commit('salesProspecting/SET_RULE_FILE_EXISTING')
            setTimeout(() => {
                if (self.$refs.uploadedOwner[index].validate()) {
                    var options = {
                        initialFormData: new FormData(),
                        howLeafArrayIndexes: true,
                        includeNullValues: false,
                        mapping: function(value) {
                            if (typeof value === 'boolean') {
                                return +value ? '1': '0';
                            }
                            return value;
                        }
                    }
                    let initialPayload = {...item, is_draft: false}
                    delete initialPayload.id
                    delete initialPayload.sales_prospects_id
                    delete initialPayload.updated_at
                    delete initialPayload.created_at
                    if (initialPayload.valid_idUploaded) {
                        delete initialPayload.valid_idUploaded
                    }
                    if (initialPayload.amlaUploaded) {
                        delete initialPayload.amlaUploaded
                    }
                    if (initialPayload.court_clearanceUploaded) {
                        delete initialPayload.court_clearanceUploaded
                    }
                    if (initialPayload.nbiUploaded) {
                        delete initialPayload.nbiUploaded
                    }
                    if (initialPayload.picture_2x2Uploaded) {
                        delete initialPayload.picture_2x2Uploaded
                    }
                    var convertedFormData = jsonToFormData(initialPayload, options)
                    const payload = {id: this.$route.params.id, payload: convertedFormData}
                    this.callUpdateOwner(payload)
                }
            })
        },
        async callUpdateOwner (payload) {
            await this.$store.dispatch('salesProspecting/doUpdateOwner', {payload})
			this.reloadOwners()
            this.$store.commit('salesProspecting/UNSET_RULE_GENERIC_EXISTING')
            this.$store.commit('salesProspecting/UNSET_RULE_EMAIL_EXISTING')
            this.$store.commit('salesProspecting/UNSET_RULE_FILE_EXISTING')
        },
        async reloadOwners () {
            const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
            this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
            this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
            this.decodeOwners(res.data.result[0].sp_owner)
        },
        decodeOwners (resOwners) {
           let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resOwners.forEach(function(e) {
                e.owner_id = e.id
				e.amlaUploaded = JSON.parse(e.amla)
				e.court_clearanceUploaded = JSON.parse(e.court_clearance)
				e.nbiUploaded = JSON.parse(e.nbi)
				e.picture_2x2Uploaded = JSON.parse(e.picture_2x2)
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
				e.court_clearance = _.clone(nonExpiryFile)
				e.nbi = _.clone(nonExpiryFile)
				e.amla = _.clone(expiryFile)
				e.picture_2x2 = _.clone(nonExpiryFile)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_OWNERS', resOwners)
        },
    },  
    computed: {
		...mapGetters({
            existingUploadedOwners: 'salesProspecting/existingUploadedOwners',
            otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
            primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
            genericRule: 'salesProspecting/genericRule',
            fileRule: 'salesProspecting/fileRule',
            emailRule: 'salesProspecting/emailRule',
            existingGenericRule: 'salesProspecting/existingGenericRule',
            existingEmailRule: 'salesProspecting/existingEmailRule',
            existingFileRUle: 'salesProspecting/existingFileRUle',
            province: 'salesProspecting/province',
            existingOwnersCity: 'salesProspecting/existingOwnersCity',
            existingOwnersZip: 'salesProspecting/existingOwnersZip',
            hrisToken: 'auth/hrisToken',
		}),
	}
};
</script>
